.select2-selection--single {
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  outline: 0;

  .select2-selection__rendered {
    color: $secondaryAccent;
    font-weight: 700;
    line-height: 28px;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-left: 15px;
    transform: rotate(0);
    transition: transform .2s ease-out;

    &:hover {
      transform: rotate(90deg);
    }
  }

  .select2-selection__placeholder {
    color: $secondaryAccent;
    font-weight: 700;
  }

  .select2-selection__arrow {
    height: 26px;

    position: absolute;

    top: 1px;
    right: 1px;

    width: 20px;

    b {
      border-color: $secondaryAccent transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;

      height: 0;
      left: 50%;

      margin-left: -4px;
      margin-top: -2px;

      position: absolute;

      top: 50%;
      width: 0;
    }
  }
}

&[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
}

&.select2-container--disabled {
  .select2-selection--single {
    background-color: #eee;
    cursor: default;

    .select2-selection__clear {
      display: none;
    }
  }
}

&.select2-container--open {
  .select2-selection--single {
    .select2-selection__arrow {
      b {
        border-color: transparent transparent $secondaryAccent transparent;
        border-width: 0 4px 5px 4px;
      }
    }
  }
}
