.select2-selection--multiple {
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  cursor: text;

  .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-top-color: $secondaryAccent;
      border-width: 5px 4px 0 4px;
      border-style: solid;
      position: absolute;
      right: 0;
      top: 50%;
    }

    li {
      list-style: none;
    }
  }

  .select2-search__field {
    border: 0;
    color: $secondaryAccent;
    font-weight: 700;

    &::placeholder {
      color: $secondaryAccent;
      font-style: normal;
      font-weight: 700;
      width: 100% !important;
    }
  }

  .select2-selection__placeholder {
    color: $secondaryAccent;

    margin-top: 5px;

    float: left;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px;
  }

  .select2-selection__choice {
    background-color: transparent;
    font-weight: 700;
    border-bottom: 1px solid $secondaryAccent;
    cursor: default;

    float: left;

    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
  }

  .select2-selection__choice__remove {
    color: $secondaryAccent;
    cursor: pointer;

    display: inline-block;
    font-weight: bold;

    margin-right: 6px;

    &:hover {
      color: $secondaryAccentHover;
    }
  }
}

&[dir="rtl"] {
  .select2-selection--multiple {
    .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
      float: right;
    }

    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}

&.select2-container--focus {
  .select2-selection--multiple {
    .select2-search__field {
      &::placeholder {
        opacity: 0;
      }
    }

    outline: 0;
  }

  .select2-selection__rendered {
    &:before {
      border-color: transparent;
      border-bottom-color: $secondaryAccent;
      border-width: 0 4px 5px 4px;
      border-style: solid;
    }
  }

}

&.select2-container--disabled {
  .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
  }

  .select2-selection__choice__remove {
    display: none;
  }
}
