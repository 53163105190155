/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */
/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */
/* =============================================================================
   IMPORTS
   ========================================================================== */
// CSS Reset
@import "inc/reset";
// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";
// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";
// Plugins
@import "plugins/validation-engine";
@import "plugins/slick.scss";
@import "plugins/select2/core";
@import "plugins/maps.scss";
//@import "plugins/fancybox";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;
}

body {
  @include default-font;
  color: $black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);

  &.calendar,
  &.workshop-register,
  &.news {
    background-color: $lightBlue;
  }

  scroll-behavior: smooth;
}

.page-wrap {
  position: relative;
  overflow-x: hidden;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }

  @media #{$medium} {
    min-height: 100vh;
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media #{$larger} {
    padding-left: 75px;
    padding-right: 75px;
  }
}

.wrapper {
}

.wrapper--small {
  max-width: 600px;
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}

/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
  transition: $transitionDefault;
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 18px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 2px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before,
  &:after {
    content: "";
    margin-left: 0;
  }
  &:before {
    margin-top: 3px;
  }
  &:after {
    margin-top: 8px;
  }
}

// Exceptions: only add what you need!
// .mb0.mb0 { margin-bottom: 0; }
// .mb20.mb20 { margin-bottom: 20px; }
// .mb40.mb40 { margin-bottom: 40px; }

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:before,
  &:after {
    display: none;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div {
      float: left;
    }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  z-index: 999999;

  @media #{$medium} {
    padding: 0 0 25px;
  }
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);

  @media #{$medium} {
    float: left;
    padding-top: 39px;
  }

  img {
    display: block;

    @media #{$medium} {
      margin-bottom: 0;
    }

    @media (max-width: 1000px) {
      width: 120px;
      margin-top: 20px;
    }

    @media #{$maxMedium} {
      width: 140px;
      margin-top: 0;
    }
  }

  &:hover,
  &:focus {
    @include opacity(80);
  }
}

.main-nav {
  @include optimize-font;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;

  li:not(.main-nav__socials) {
    a {
      text-decoration: none;
      color: $secondaryAccent;
      padding: 12px 15px;
      display: block;
      border-top: 1px solid $grayLight;
    }
  }

  li:last-of-type a {
    border-bottom: 1px solid $grayLight;
  }

  li.active,
  li:hover {
    &:before {
      width: 26px !important;
    }
  }

  a:hover,
  a:focus,
  li.active a {
    color: $accent;
  }

  li.main-nav__socials {
    text-align: center;
    border-top: 1px solid $grayLight;
    padding: 10px 0;
    font-size: 18px;

    a {
      display: inline-block;
      text-decoration: none;
      margin: 0 10px;
      color: $accent;

      &:hover,
      &:focus {
        color: $accentHover;
      }
    }

    @media #{$medium} {
      display: none;
    }
  }

  @media #{$medium} {
    margin-top: 87px;
    float: right;

    li:not(.main-nav__socials) {
      float: left;
      margin: 0 0 0 20px;
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: -11px;
        left: 0;
        height: 3px;
        width: 0;
        transition: width $transitionDefault;
        background-color: $accent;
      }

      a {
        border-bottom: 0;
        border-top: 0;
        padding: 0;
      }
    }
  }

  @media #{$large} {
    li {
      margin-left: 45px;
    }
  }
}

.main-nav__trigger {
  display: block;
  padding: 14px 20px 11px 50px;
  background-color: transparent;
  color: $black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  z-index: 1;
  background-color: $white;
  top: 1px;
  right: 20px;
  border: 1px solid #efefef;
  margin-top: 0;

  .icon-hamburger {
    position: absolute;
    left: 12px;
    top: 19px;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:active {
    transform: none;
  }

  @media #{$medium} {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0;

  @media #{$maxMedium} {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url("../images/mobile-bg.png");
    background-repeat: repeat;
    text-align: center;
    padding-top: 100px;
  }

  @media #{$medium} {
    margin: 0 0 0 -20px;
    display: block;
  }
}

.page-header__socials-wrapper {
  float: right;
  margin-top: 46px;
  margin-left: 35px;
  margin-bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  a {
    color: $accent;
    text-decoration: none;

    &:hover {
      color: $accentHover;
    }
  }

  .icon-newsletter {
    font-size: 25px;
    margin-right: 5px;
  }

  .smaller {
    font-size: 13px;
  }

  @media #{$larger} {
    margin-left: 75px;
  }
}

.page-header__socials,
.insta-link {
  display: none;
  list-style: none;

  li {
    float: left;
    font-size: 25px;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    color: $accent;
    text-decoration: none;

    &:hover {
      color: $accentHover;
    }
  }

  @media #{$medium} {
    display: block;
  }
}

.insta-link {
  margin-left: 0;
  margin-top: 0;
  float: left;

  display: block !important;

  a {
    display: flex;
    span {
      margin-left: 5px;
      font-family: "Poppins", Helvetica, Arial, Verdana, sans-serif;
      font-size: 14px;
      padding-top: 5px;
      line-height: 1;
    }
  }
}

.cc-color-override-530831885 .cc-btn {
  background: $accent !important;
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}

.visual {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 66.66%; // picture default

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    z-index: 1;
    transition: background-color 0.4s ease-out;
  }

  &.yellow {
    &:before {
      background-color: $yellow;
    }
  }

  &.red {
    &:before {
      background-color: $red;
    }
  }

  &.blue {
    &:before {
      background-color: $blue;
    }
  }

  &.green {
    &:before {
      background-color: $green;
    }
  }
}

.visual__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.visual--square {
  padding-top: 100%;
}

.visual--banner {
  padding-top: 21.25%;
}

.visual--banner-aanbod {
  display: none;
  position: relative;

  .visual__img {
    background-position: 0 10%;
  }

  @media #{$medium} {
    display: block;
  }
}

.visual--team-member {
  padding-top: 78.3%;
}

/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  padding-top: 100px;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }

  @media #{$medium} {
    padding: 132px 0 476px;
    min-height: 100vh;
  }
}

/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  padding-top: 40px;
  padding-bottom: 15px;

  a {
    color: $black;
  }

  .grid {
    margin-bottom: 0;
  }

  @media #{$medium} {
    height: 476px;
    margin: -476px 0 0;
    padding-top: 60px;
    background-image: url("../images/footer-bg.png");
    background-size: 120% 90%;
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}

.footer__logo {
  margin: 0 auto 40px auto;

  @media #{$medium} {
    margin-bottom: 40px;
  }
}

.page-footer__top-content {
  text-align: center;
}

.page-footer__contact-info {
  margin-bottom: 35px;
}

.page-footer__socials {
  display: inline-block;
  margin-bottom: 10px;

  ul {
    list-style: none;
    margin: 0;
    @include clearfix;
  }

  li {
    float: left;
    font-size: 30px;
    margin: 0 5px;
  }

  a {
    color: $accent;
    transition-duration: 0.1s;

    &:hover {
      color: $accentHover;
    }
  }
}

.page-footer__bottom-content {
  font-size: 14px;
  text-align: center;

  &,
  a {
    color: $gray;
  }

  @media #{$medium} {
    text-align: left;
  }
}

.page-footer__links {
  display: inline-block;
  margin: 30px 0;

  a {
    display: block;
    margin: 0 auto;
    line-height: 1.5em;

    @media #{$medium} {
      display: inline-block;
      margin-right: 15px;
      margin-left: 15px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  span {
    display: none;

    @media #{$medium} {
      display: inline-block;
    }
  }

  @media #{$medium} {
    margin: 0;
  }
}

.certified-by {
  display: block;

  @media #{$medium} {
    display: inline-block;
    width: 33%;
  }
}

.certification-image {
  margin-left: 20px;
}

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  &:hover,
  &:focus {
    opacity: 1;
  }
  @media #{$medium} {
    width: 16px;
    position: absolute;
    right: 45px;
    bottom: 0;
    &:hover,
    &:focus {
      opacity: 1;
      width: 80px;
    }
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover,
    &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article__author {
  font-size: 14px;
  margin-bottom: 35px;

  @media #{$medium} {
    margin-bottom: 50px;
  }

  a {
    color: $accent;
    &:hover {
      color: $accentHover;
    }
  }
}

.article-detail {
}

.article-detail__image {
  margin-bottom: 1.5em;
}

// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;

  ul,
  p {
    margin-bottom: 1em;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

// Slideshow
.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: $transitionDefault;

    &:hover,
    &:focus {
      transition: none;
    }
  }

  span:hover,
  span:focus,
  .cycle-pager-active {
    text-indent: 0;
  }
}

// Map
.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;

  @media #{$medium} {
    height: 550px;
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: $accent;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3c5b9b;
  &:hover,
  &:focus {
    background-color: darken(#3c5b9b, 8%);
  }
}

.social__link--twitter {
  background-color: #2daae1;
  &:hover,
  &:focus {
    background-color: darken(#2daae1, 8%);
  }
}

.social__link--pinterest {
  background-color: #cb2027;
  &:hover,
  &:focus {
    background-color: darken(#cb2027, 8%);
  }
}

.social__link--linkedin {
  background-color: #0173b2;
  &:hover,
  &:focus {
    background-color: darken(#0173b2, 8%);
  }
}

.block {
  padding: 0 0 40px 0;

  &:after {
    content: "";
    display: block;
    margin: 40px 0 -40px;
  }

  @media #{$medium} {
    padding: 0 0 110px 0;

    &:after {
      margin: 110px 0 -110px;
    }
  }
}

.block-smaller {
  padding: 0 0 40px 0;

  &:after {
    content: "";
    display: block;
    margin: 40px 0 -40px;
  }

  @media #{$medium} {
    padding: 0 0 100px 0;

    &:after {
      margin: 100px 0 -100px;
    }
  }
}

.category-menu {
}

.yellow {
  color: $yellow !important;

  a {
    color: $yellow !important;
  }
}

.red {
  color: $red !important;

  a {
    color: $red !important;
  }
}

.green {
  color: $green !important;

  a {
    color: $green !important;
  }
}

.blue {
  color: $blue !important;

  a {
    color: $blue !important;
  }
}

.category-menu__item {
  padding: 25px 0;
  user-select: none;
  text-align: center;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    z-index: -1;
    transition: height 0.2s ease-out;
  }

  &.active {
    pointer-events: none;
  }

  .icon {
    font-size: 35px;
    margin-right: 25px;

    @media #{$larger} {
      font-size: 60px;
    }
  }

  a {
    text-decoration: none;
    display: inline-block;
    text-align: left;
    min-width: 130px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @media #{$medium} {
      min-width: 0;
    }
  }

  @media #{$medium} {
    padding: 45px 0;
    text-align: center;
  }

  &.yellow {
    @media #{$maxMedium} {
      background-color: $yellow;
      color: white !important;
      a {
        color: white !important;
      }
    }

    &:before {
      background-color: $yellow;
    }

    &:hover,
    &.active {
      color: $white !important;
      a {
        color: $white !important;
      }

      &:before {
        height: 100%;
      }
    }
  }

  &.red {
    @media #{$maxMedium} {
      background-color: $red;
      color: white !important;
      a {
        color: white !important;
      }
    }

    &:before {
      background-color: $red;
    }

    &:hover,
    &.active {
      color: $white !important;
      a {
        color: $white !important;
      }

      &:before {
        height: 100%;
      }
    }
  }

  &.green {
    @media #{$maxMedium} {
      background-color: $green;
      color: white !important;
      a {
        color: white !important;
      }
    }

    &:before {
      background-color: $green;
    }

    &:hover,
    &.active {
      color: $white !important;
      a {
        color: $white !important;
      }

      &:before {
        height: 100%;
      }
    }
  }

  &.blue {
    @media #{$maxMedium} {
      background-color: $blue;
      color: white !important;
      a {
        color: white !important;
      }
    }

    &:before {
      background-color: $blue;
    }

    &:hover,
    &.active {
      color: $white !important;
      a {
        color: $white !important;
      }

      &:before {
        height: 100%;
      }
    }
  }
}

.circle-link {
  text-decoration: none;
  font-weight: 700;
  display: inline-block;
  position: relative;
  margin: 20px 0;
  padding-left: 19px;
  user-select: none;

  .icon {
    display: inline-block;
    margin: 0 0 4px 4px;
    font-size: 6px;
    color: $secondaryAccent;
    transition: color 0.05s ease-out;

    @media #{$medium} {
      margin-left: 15px;
    }
  }

  &:before {
    content: "";
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $accent;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateX(100%) translateY(-50%);
    z-index: -1;
    transition: all 0.2s ease-out;
  }

  &:hover {
    color: $secondaryAccent;

    .icon {
      transition-delay: 0.15s;
      color: $white;
    }

    &:before {
      right: 0;
      transform: translateX(37%) translateY(-50%);
    }
  }

  &[disabled] {
    color: #adadad;
    pointer-events: none;

    &:before {
      background-color: $inputBorderColor;
    }

    .icon {
      color: #adadad;
    }
  }
}

.calendar {
}

.card {
  background-color: $lightBlue;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  transition: all 0.2s ease-out;
  overflow: hidden;

  &:before {
    content: "\e90a";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    color: $white;
    font-size: 115px;
    font-family: "icomoon" !important;
    speak: none;
    line-height: 1;
    transition: transform 0.4s ease-out;
    transform: translateX(100%) translateY(100%) rotate(-90deg);
  }

  &:hover {
    -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.05);

    &:before {
      transform: translateX(20%) translateY(30%) rotate(15deg);
    }
  }
}

.card__content {
  padding: 40px 40px 75px 40px;
}

.card__article-preview {
  height: 7em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.card--has-visual {
  background-color: $white;
  border: 1px solid #efefef;

  .visual {
    padding-top: 51%;
  }

  &:before {
    z-index: 1;
    bottom: auto;
    top: 0;
    transform: translateX(100%) translateY(-100%) rotate(90deg);
  }

  &:hover {
    &:before {
      transform: translateX(30%) translateY(-20%) rotate(-25deg);
    }
  }
}

.card--red {
  .visual {
    border-bottom: 5px solid $red;
  }
}

.card--yellow {
  .visual {
    border-bottom: 5px solid $yellow;
  }
}

.card--blue {
  .visual {
    border-bottom: 5px solid $blue;
  }
}

.card--green {
  .visual {
    border-bottom: 5px solid $green;
  }
}

.card__card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.card__date,
.card__location {
  display: block;
  font-weight: 300;
  margin-right: 2px;
  white-space: nowrap;

  .icon {
    color: $accent;
    line-height: 24px;
    margin-bottom: 4px;
  }

  span {
    display: inline-block;
    vertical-align: center;
  }
}

.card__date {
  .icon-clock {
    font-size: 15px;
    margin-right: 2px;
  }
}

.card__location {
  margin-bottom: 10px;
}

.card__exclamation {
  font-weight: 300;

  .icon {
    color: $accent;
    display: inline-block;
    margin-right: 10px;

    &:before {
      position: relative;
      top: -3px;
    }
  }
}

.card__links {
  position: absolute;
  bottom: 40px;
  z-index: 2;
}

.card__cta {
  margin-right: 20px;
  color: $accent;

  &:hover,
  &:focus {
    color: $accentHover;
  }
}

.card__cta--inactive {
  font-weight: 300;
  color: $accent;
  text-decoration: none;
  pointer-events: none;

  &:hover,
  &:focus {
    color: $accent;
  }
}

.card__info {
  white-space: nowrap;
  color: $gray;

  &:hover,
  &:focus {
    color: $grayDark;
  }
}

.review-card {
  position: relative;
  background-color: $lightBlue;
  overflow: hidden;
  @include clearfix;

  @media #{$medium} {
    padding-left: 33%;
  }
}

.review-card__image {
  display: block;
  width: 100%;
  max-width: 300px;
  height: 230px;
  margin: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
  z-index: 1;

  @media #{$medium} {
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 33%;
    max-width: none;
    margin: 0;
  }
}

.review-card__content {
  padding: 20px;

  @media #{$medium} {
    max-width: 530px;
    padding: 60px 20px;
    margin: 0 auto;

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      height: 34px;
      left: 0;
      right: 0;
      background-color: $white;
    }
  }
}

.review-card__quote {
  font-weight: 700;
  color: $secondaryAccent;
  min-height: 7em;
}

.review-card__person {
  color: $accent;
}

.review-card__person-info {
  color: $secondaryAccent;
}

.slider-wrap {
  position: relative;
}

.slider {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease-out;

  .slider__slide {
    display: none;
    &:first-of-type {
      display: block;
    }
  }

  &.slick-initialized {
    opacity: 1;
    visibility: visible;

    .slider__slide {
      display: block;
    }
  }
}

.slider__slide {
  display: block !important;
}

.slider__actions {
  display: block;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  margin: 10px 0;
  user-select: none;

  @media #{$medium} {
    position: absolute;
    margin: 10px 0 0 0;
  }
}

.slider__dots {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  @include clearfix;
  max-width: 55%;

  @media #{$medium} {
    max-width: none;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    float: left;
    padding: 14px 10px;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: $accent;
      border-radius: 50%;
      transition: opacity 0.2s ease-out;
      opacity: 0.5;
    }

    &.slick-active {
      pointer-events: none;

      &:before {
        opacity: 1;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
      cursor: pointer;
    }
  }

  button {
    visibility: hidden;
    height: 6px;
    width: 6px;
    padding: 0;
    margin: 0;
  }
}

.slider__action-left,
.slider__action-right {
  display: inline-block;
  vertical-align: middle;
  font-size: 6px;
  padding: 5px 9px;
  color: $accent;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }
}

.floating-card {
  position: relative;
  background-color: $white;
  overflow: hidden;

  @media #{$medium} {
    padding: 50px;
    -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
  }

  @media #{$larger} {
    padding: 50px 100px;
  }
}

.floating-card-wrap--review-score {
  padding-top: 50px !important;
  color: $secondaryAccent;
  font-size: 18px;
  text-align: center;

  .accent {
    color: $accent;
  }

  @media #{$medium} {
    font-size: 20px;
  }

  &:before {
    height: 100px !important;
  }

  .floating-card {
    margin-bottom: 42px;
    position: relative;
    z-index: 0;
    overflow: visible;

    &:before {
      content: "";
      display: block;
      width: 150px;
      height: 140px;
      background-image: url("../images/thumb-up.png");
      background-size: contain;
      background-position: bottom left;
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: -1;

      @media #{$medium} {
        bottom: 0;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
}

.home-newsletter {
  .floating-card {
    @media #{$medium} {
      z-index: 0;

      &:before {
        font-family: "icomoon" !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e90a";
        display: block;
        font-size: 170px;
        color: $lightBlue;
        position: absolute;
        left: -55px;
        bottom: 45px;
        transform: scaleX(-1) rotate(-15deg);
        z-index: -1;
      }
    }
  }
}

.newsletter__content {
  @media #{$medium} {
    margin-top: 10px;
  }
}

.newsletter-form {
  position: relative;
  transform: translateX(-13px);

  .checkbox-statement {
    margin-left: 13px;
    margin-bottom: 26px;

    label {
      font-size: 13px;
      padding-top: 3px;
      color: $gray;
    }
  }

  .form__item-profession {
    display: flex;
    align-items: center;
    padding: 0 13px;
  }
}

.newsletter-form__email {
  input {
    border: 0 !important;
    box-shadow: none !important;
    padding: 25px 14px 11px 14px !important;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    left: 13px;
    right: 0;
    bottom: -10px;
    background-color: $gray;
    z-index: 1;
  }
}

.floating-card-wrap {
  position: relative;

  .floating-card__bg {
    display: block;
    width: 100%;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 40px;
  }

  @media #{$medium} {
    padding: 75px 40px 0 40px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 225px;
      width: 100%;
      background-color: $lightBlue;
    }

    .floating-card__bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 225px;
      width: 100%;
    }
  }

  @media #{$larger} {
    padding: 75px 100px 0 100px;
  }
}

.floating-card-wrap--article {
  @media #{$medium} {
    padding-top: 205px;

    .floating-card__bg {
      height: 275px;
    }
  }

  .floating-card {
    @media #{$medium} {
      padding-top: 70px;
      box-shadow: none !important;
    }
  }
}

.newsletter-form__submit {
  position: absolute;
  bottom: 0;
  right: 0;

  input {
    margin: 0;
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  &:before {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: $secondaryAccent;
    font-size: 6px;
    transition: color 0.2s ease-out;
  }

  &:hover {
    input {
      background-color: $accent;
    }

    &:before {
      color: $white;
    }
  }

  &[disabled] {
    &:before {
      color: $white;
    }
  }

  @media #{$maxMedium} {
    input {
      background-color: $accent;
    }

    &:before {
      color: $white;
    }
  }
}

.tab-panel {
  display: none;

  &.active {
    display: block;
  }
}

.tab-control {
  user-select: none;
}

.breadcrumb {
  color: $accent;
  font-weight: 600;
  margin-bottom: 25px;

  @media #{$medium} {
    margin-bottom: 55px;
  }

  a {
    color: $accent;
    text-decoration: none;

    &:hover {
      color: $accentHover;
    }
  }

  .icon-arrow-left {
    display: inline-block;
    vertical-align: middle;
    font-size: 6px;
    margin-bottom: 4px;
    margin-right: 5px;
  }
}

.intro {
  position: relative;

  @media #{$medium} {
    &:before {
      font-family: "icomoon" !important;
      font-size: 430px;
      line-height: 430px;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(-13deg);
      transform-origin: bottom left;
      opacity: 0.05;
      z-index: -1;
    }

    &.intro--yellow {
      &:before {
        content: "\e907";
        color: $yellow;
      }
    }

    &.intro--red {
      &:before {
        content: "\e906";
        color: $red;
      }
    }

    &.intro--blue {
      &:before {
        content: "\e904";
        color: $blue;
      }
    }

    &.intro--green {
      &:before {
        content: "\e905";
        color: $green;
      }
    }
  }
}

.control-list-title {
  color: $secondaryAccent;
  background-color: $secondaryAccentFaded;
  font-weight: 300;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 14px;

  @media #{$medium} {
    font-size: 18px;
  }
}

.control-list {
  position: relative;
  @include clearfix;
  padding: 10px;

  dt {
    color: $secondaryAccent;
    font-weight: 400;
  }

  dd {
    color: $black;
  }

  @media #{$medium} {
    dt {
      width: 50%;
      padding-right: 20px;
      float: left;
      clear: left;
      margin-bottom: 15px;
    }

    dd {
      width: 50%;
      float: left;
    }
  }
}

.control-list--participant {
  background-color: $white;
  border: 1px solid $inputBorderColor;
  padding: 10px;

  dt:last-of-type {
    margin-bottom: 0;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999999999;
  display: none;

  &.show {
    display: block;
  }
}

.modal__content {
  margin: 0 auto;
  background-color: $white;
  padding: 5px;
  position: absolute;
  width: 100%;
  max-height: 95vh;
  max-height: calc(100vh - 85px);
  max-width: 1200px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: auto;
  min-width: 300px;

  @media #{$medium} {
    max-height: 95vh;
    padding: 20px;
  }
}

.modal__body {
  overflow-y: auto;
  max-height: 95vh;
  max-height: calc(100vh - 90px);
  padding: 20px 0;
  background-color: $white;
  @media #{$medium} {
    max-height: 95vh;
    max-height: calc(95vh - 120px);
  }
}

.modal__close {
  position: absolute;
  right: 10px;
  top: -44px;
  transition: transform 0.2s ease-out;
  transform: rotate(0deg);
  font-size: 14px;
  padding: 10px;
  color: $white;

  @media #{$medium} {
    right: -10px;
  }

  &:hover {
    cursor: pointer;
    transform: rotate(90deg);
  }
}

.img-full-width {
  width: 100%;
}

.accordion-trigger {
  cursor: pointer;
}

select {
  visibility: hidden;
  opacity: 0;
}

.add-button {
  float: none;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 40px;

  &:before {
    display: inline-block;
    content: "+";
    font-weight: 300;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.2s ease-out;
  }
}

.close-button {
  float: none;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 40px;
  transition: all 0.2s ease-out;

  &:before {
    display: inline-block;
    content: "+";
    font-weight: 300;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transition: transform 0.2s ease-out;
  }
}

.tooltip {
  display: inline-block;
  font-size: 12px;
  background-color: $secondaryAccentFaded;
  color: $secondaryAccent;
  padding: 10px 10px;
  border-radius: 3px;
  position: relative;
  user-select: none;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.15s ease-out;

  &:before {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border: 5px solid transparent;
    border-top: 3px solid $secondaryAccentFaded;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip--hidden {
  opacity: 0;
}

ul.pagination {
  list-style: none;
  @include clearfix;

  li {
    float: left;
    margin: 0 10px;
    position: relative;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      color: $secondaryAccent;
    }

    &.disabled {
      color: $gray;
    }

    a {
      color: $gray;

      &:hover {
        color: $secondaryAccent;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 1px;
      background-color: $gray;
      position: absolute;
      right: -13px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:first-of-type,
    &:last-of-type,
    &:nth-last-child(2) {
      &:before {
        content: none;
      }
    }
  }
}

/* =============================================================================
   PAGES
   ========================================================================== */

.visual-intro {
  position: relative;
  font-weight: 300;
  color: $secondaryAccent;
  margin-bottom: 30px;

  @media #{$medium} {
    margin-bottom: 0;
  }

  h1 {
    font-weight: 300;
  }

  &:before {
    content: "";
    display: block;
    width: 110%;
    height: 0;
    padding-top: 66.66%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media #{$medium} {
      height: auto;
      width: auto;
      padding-top: 0;
      position: absolute;
      top: 0;
      left: 20px;
      bottom: 0;
      right: 38%;
      background-position: 75% 20%;
    }
  }
}

.visual-intro--home {
  &:before {
    background-image: url("../images/home-hero--mobile.jpg");

    @media #{$medium} {
      background-image: url("../images/home-hero.jpg");
    }
  }

  @media #{$medium} {
    padding-bottom: 85px;
  }
}

.visual-intro--about {
  &:before {
    background-image: url("../images/wie-we-zijn--mobile.jpg");

    @media #{$medium} {
      background-image: url("../images/wie-we-zijn.jpg");
    }
  }
}

.visual-intro__content {
  padding: 40px 0;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
    bottom: 0;
    background-color: $white;
    -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
    z-index: -1;

    @media #{$medium} {
      left: 0;
      right: -100px;
    }
  }

  @media #{$medium} {
    padding: 115px 30px 115px 65px;
  }

  @media #{$massive} {
    padding: 150px 0 150px 110px;
  }
}

.visual-intro__content--smaller {
  @media #{$medium} {
    padding: 75px 30px 75px 65px;
  }

  @media #{$massive} {
    padding: 75px 0 75px 110px;
  }
}

.circle-link--calendar {
  margin-top: 20px;
}

.home-about__visual {
  margin-bottom: 40px;
  padding-top: 70%;

  .visual__img {
    background-image: url("../images/wie-we-zijn--mobile.jpg");

    @media #{$medium} {
      background-image: url("../images/wie-we-zijn.jpg");
    }
  }

  @media #{$medium} {
    margin-bottom: 0;
  }
}

.home-about__content {
  @media #{$medium} {
    max-width: 385px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.home-about__circle-link {
  text-align: right;

  @media #{$medium} {
    text-align: left;
  }
}

.home-kmo__content {
  background-color: $lightBlue;
  position: relative;
  z-index: 1;

  @media #{$medium} {
    &:before {
      content: "";
      display: block;
      width: 130px;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      background-color: $white;
      z-index: -1;
    }
  }
}

.home-kmo__left {
  padding: 40px;

  p:last-of-type {
    margin-bottom: 0;
  }

  @media #{$medium} {
    padding: 140px 100px;
  }
}

.home-kmo__right {
  background-color: $white;
  max-width: 400px;
  margin: 0 auto;

  p {
    color: $gray;

    a {
      color: $gray;

      &:hover {
        color: $grayDark;
      }
    }
  }

  @media #{$medium} {
    margin-top: 85px;
    margin-bottom: 70px;
  }
}

.home-kmo__right-button {
  border: 1px solid $grayLight;
  padding: 20px 25px;

  @media #{$medium} {
    padding: 23px 50px;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}

.home-kmo__right-title {
  color: $secondaryAccent;
  font-weight: 600;
  margin-bottom: 15px;
}

.home-kmo__text {
  max-width: 515px;
}

.aanbod-intro {
  position: relative;
}

.aanbod-intro__content {
  max-width: 720px;
}

.beroep-intro,
.event-intro {
  position: relative;
}

.beroep-intro__content,
.event-intro__content {
  max-width: 760px;
}

.event-intro {
  h1 {
    @media #{$medium} {
      width: calc(100% - 160px);
    }
  }

  p:first-of-type {
    clear: right;
  }
}

.event-intro__register-link {
  float: right;
  padding: 20px 40px;
  border-radius: 4px;
  position: relative;
  z-index: 1;

  @media #{$maxMedium} {
    padding: 15px 20px;
    font-size: 12px;
    margin-top: 0;
  }

  &.hidden--tablet {
    margin-bottom: 25px;
  }

  .icon {
    font-size: 6px;
    transform: translateY(2px) rotate(90deg);
    margin-right: 0;
  }

  &:focus {
    color: $white;
    background-color: $accent;
  }

  &:hover {
    color: $white;
    background-color: $accentHover;
  }
}

.list-filters {
  margin: 50px 0;

  .tab-list {
    text-align: right;

    margin-bottom: 30px;
    &.hidden--tablet {
      margin-top: 30px;
      text-align: center;
      font-size: 16px;
    }

    @media #{$medium} {
      float: right;
    }
  }

  .tab-list__tab {
    display: inline-block;

    a {
      color: $gray;
    }

    &.active {
      pointer-events: none;
      a {
        text-decoration: none;
        color: $secondaryAccent;

        &:hover {
          color: initial;
        }
      }
    }
  }

  .select-wrap {
    display: inline-block;
    position: relative;
  }
}

.list-filters--home {
  margin-top: 0;
}

.list-filters__sentence {
  font-size: 18px;
  font-weight: 300;
  color: $secondaryAccent;
  margin-bottom: 45px;

  select {
    display: inline-block;
    width: auto;
  }

  @media #{$medium} {
    font-size: 24px;
  }
}

.list-filters__submit {
  border-radius: 50%;
  float: none;
  margin: 0;
  margin-left: 15px;
  padding: 10px;
  font-size: 11px;

  @media #{$medium} {
    padding: 15px;
    font-size: 16px;
  }
}

.list-filters__tags-clarification {
  display: block;
  margin-right: 45px;
  color: $gray;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 10px;

  @media #{$medium} {
    display: inline-block;
    width: auto;
    margin-bottom: 0;
  }
}

.list-filters__tags {
  display: inline;
  vertical-align: middle;
  input {
    display: none;
  }

  label {
    background-color: $white;
    color: $secondaryAccent;
    display: inline-block;
    padding: 1px 14px;
    border: 1px solid $inputBorderColor;
    user-select: none;
    margin: 5px 10px 5px 0;
    width: 45%;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    @media #{$medium} {
      width: auto;
    }
  }

  input:checked + label {
    background-color: $blue;
    color: $white;
  }
}

.workshop-details {
  dt {
    color: $secondaryAccent;
  }

  dd {
    margin-bottom: 15px;
  }
}

.customer-use-kmo-wallet__info {
  font-size: 13px;
  display: inline-block;
  margin-top: 5px;
}

.register-workshop__tab-navigation {
}

.register-workshop__next {
  float: right;
}

.register-workshop__previous {
  display: inline-block;
  margin: 20px 0;
}

.register-workshop__tab-navigation--centered {
  position: relative;

  @media #{$medium} {
    .register-workshop__previous {
      position: absolute;
      right: 50%;
      right: calc(50% + 110px);
    }
    .register-workshop__next {
      float: none;
      position: absolute;
      left: 50%;
      left: calc(50% + 10px);
    }
  }
}

.register-date {
  margin: 20px 0;
}

.register-step-label {
  margin-bottom: 20px;
  font-weight: 700;
  color: $secondaryAccent;

  @media #{$medium} {
    font-size: 22px;
    line-height: 1.2em;
  }
}

.register__step-tab-list {
  position: relative;
  text-align: center;
  user-select: none;
  margin-bottom: 40px;
  margin-top: 35px;

  @media #{$medium} {
    margin-bottom: 80px;
  }

  ul {
    margin: 0;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    height: 2px;
    width: 100%;
    background-color: $secondaryAccent;
  }
}

.register-step__tab {
  display: inline-block;
  position: relative;
  margin: 0 23px;
  border-radius: 50%;
  background-color: $secondaryAccent;

  &.active,
  &:hover {
    background-color: $accent;
  }

  a {
    color: $white;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    display: block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    position: relative;
    text-align: center;

    @media #{$medium} {
      font-size: 19px;
      width: 38px;
      height: 38px;
      line-height: 38px;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.register__participant-card {
  position: relative;
}

.register__participant-card-content {
  margin-bottom: 40px;
  background-color: $white;
  opacity: 1;
  height: 301px;
  position: relative;
  transition: all 0.6s ease-out;
  transform: translateY(0);
  padding: 20px;
  border: 1px solid $inputBorderColor;
  overflow: hidden;

  @media #{$medium} {
    padding: 40px;
  }

  fieldset {
    .form__item:last-of-type {
      margin-bottom: 0;
    }
  }
}

.register__card-close-button {
  position: absolute;
  top: -28px;
  right: -16px;
  z-index: 1;
  transition: all 0.6s ease-out;

  float: none;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 40px;

  &:before {
    display: inline-block;
    content: "+";
    font-weight: 300;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transition: transform 0.2s ease-out;
  }
}

.register__participant-card--hidden {
  .close-button,
  .register__card-close-button {
    opacity: 0;
    top: -8px;
  }

  .register__participant-card-content {
    transform: translateY(30px);
    opacity: 0;
    height: 0;
    margin-bottom: 0;
    padding: 0;
  }
}

.register__participant-card-template {
  display: none;
}

.register__add-button-wrap {
  text-align: center;
  position: relative;
  padding-top: 38px;
  padding-bottom: 38px;

  .add-button {
    margin: 0;
  }

  &:before {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 104, 0.08);
  }
}

.register-status {
  h1 {
    padding-top: 50px;
  }
}

.input-group--radio {
  border: 1px solid $inputBorderColor;
  border-radius: 4px;
  background-color: $white;
  margin-bottom: 20px;
}

.radio {
  padding: 15px;
  border-bottom: 1px solid $inputBorderColor;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
    display: block;
    padding-left: 20px;
    width: 100%;
    margin-bottom: 0px;
  }

  label > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.radio-input {
  width: 18px;
  height: 18px;
  background-color: $white;
  border-color: $inputBorderColor;
}

.tooltip-add-participant {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.about-who__visual {
  margin-bottom: 20px;
  position: relative;
  width: 100%;

  .visual {
    padding-top: 71.5%;
  }

  @media #{$medium} {
    margin-bottom: 0;
  }
}

.about-who__content {
  p:last-of-type {
    margin-bottom: 0;
  }

  @media #{$medium} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }
}

.about-team__grid {
  @media #{$medium} {
    &:nth-of-type(2n + 3) {
      clear: left;
    }
  }

  @media #{$large} {
    &:nth-of-type(3n + 4) {
      clear: left;
    }
  }
}

.team-member {
  margin-bottom: 20px;
  position: relative;

  .visual {
    margin-bottom: 25px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $secondaryAccent;
      opacity: 0;
      transition: opacity 0.2s ease-out;
    }

    &:after {
      content: "+";
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      font-weight: 700;
      font-size: 51px;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(0.7);
      color: $white;
      z-index: 1;
      opacity: 0;
      transition: all 0.4s ease-out;
    }
  }

  &:hover {
    .visual {
      &:before {
        opacity: 0.5;
      }

      &:after {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%) rotate(0deg) scale(1);
      }
    }
  }
}

.team-member__name {
  a {
    font-weight: 700;
    color: $secondaryAccent;
    text-decoration: none;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.modal-team-member {
  .modal__content {
    width: 100%;
  }
}

.modal-team-member__left-content {
  @media #{$medium} {
    max-width: 380px;
  }
}

.modal-team-member__picture {
  margin-bottom: 15px;
}

.modal-team-member__contact-info {
  color: $accent;
  font-weight: 700;
  margin-bottom: 40px;

  a {
    color: $accent;
    text-decoration: none;

    &:hover {
      color: $accentHover;
      text-decoration: underline;
    }
  }
}

.modal-team-member__desc {
  margin-bottom: 40px;
}

.modal-team-member__personal-motivation {
  background-color: $lightBlue;
  padding: 20px;
  margin-bottom: 40px;
  color: $secondaryAccent;
  font-weight: 700;

  @media #{$medium} {
    padding: 50px;
  }
}

.modal-team-member__education {
  ul {
    margin: 0;
    list-style: none;
    font-size: 13px;
  }

  li {
    border-top: 1px solid $gray;
    padding: 10px 0;

    &:first-of-type {
      border-top: 0;
    }
  }
}

#contact-form {
  .checkbox-statement {
    float: left;
    max-width: 350px;
  }

  .contact__submit {
    float: right;
  }
}

.news__content {
  margin-bottom: 40px;

  h1 {
    margin-bottom: 30px;
  }
}

.news__navigation {
  text-align: center;

  a {
    color: $accent;
    display: inline-block;
    margin: 15px 20px;

    &:hover {
      color: $accentHover;
    }

    &.back-to-overview {
      color: $gray;

      &:hover {
        color: $grayDark;
      }
    }
  }

  @media #{$medium} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    a {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.about-story {
}

.about-story__step {
  position: relative;
  padding-left: 60px;

  &:not(:first-of-type) {
    padding-top: 20px;

    .about-story__step-nr {
      top: 25px;
    }

    .accordion-trigger {
      &:after {
        top: 20px;
      }
    }
  }

  &:after {
    content: "";
    display: block;
    margin: 40px 0 -40px;
  }

  @media #{$medium} {
    padding-left: 200px;
  }

  .accordion-trigger {
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 3px;
      top: 0;
      bottom: 0;
      left: 40px;
      background-color: $accent;
      transition: all 0.2s ease-out;
      opacity: 1;

      @media #{$medium} {
        left: 175px;
        width: 5px;
      }
    }

    &:after {
      content: "-";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      color: $accent;
      font-size: 20px;
      font-weight: 700;
      white-space: nowrap;
      @media #{$medium} {
        font-size: 24px;
        position: static;
        display: inline;
      }
    }

    &.active {
      &:before {
        opacity: 0.5;
      }

      &:after {
        content: "+";
      }
    }

    h3 {
      display: inline-block;
      padding-right: 20px;
    }
  }
}

.about-story__step-content {
  h3 {
    font-size: 18px;

    @media #{$medium} {
      font-size: 24px;
    }
  }
}

.about-story__step-text {
  width: 100% !important;
  overflow: hidden;
}

.about-story__step-nr {
  font-size: 20px;
  font-weight: 700;
  color: $accent;
  position: absolute;
  left: 0;
  top: 5px;

  @media #{$medium} {
    left: 100px;
    font-size: 24px;
  }
}

#map {
  height: 500px;
}

.discount-code-wrap {
  margin-bottom: 15px;

  .form__item {
    display: flex;
    align-items: center;

    div {
      flex-grow: 1;
    }

    button {
      margin: 0;
      padding: 9px 20px;
    }
  }
}
.js-check-discount-code__response__message {
  display: none;
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }

  .page-footer,
  .main-nav {
    display: none;
  }

  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}
