
/*============================================================================
  TYPOGRAPHY
==============================================================================*/

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?ojb00b');
  src:  url('../fonts/icomoon.eot?ojb00b#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?ojb00b') format('truetype'),
  url('../fonts/icomoon.woff?ojb00b') format('woff'),
  url('../fonts/icomoon.svg?ojb00b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-exclamation:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e90e";
}
.icon-close:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90a";
}
.icon-plus-broadcast:before {
  content: "\e904";
}
.icon-plus-microphone:before {
  content: "\e905";
}
.icon-plus-people:before {
  content: "\e906";
}
.icon-plus-pencil:before {
  content: "\e907";
}
.icon-linkedin:before {
  content: "\e900";
}
.icon-instagram:before {
  content: "\e901";
}
.icon-twiter:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-newsletter:before {
  content: "\ea86";
}
.icon-arrow-left:before {
  content: "\e908";
}
.icon-arrow-right:before {
  content: "\e909";
}
.icon-clock:before {
  content: "\e90b";
}
.icon-marker:before {
  content: "\e90c";
}

// Font Stacks
@mixin default-font($weight: 400, $lineHeight: 1.5em) {
  @include optimize-font;
  font-family: 'Poppins', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  line-height: $lineHeight;
  font-weight: $weight;
}

@mixin display-font() {
  font-family: 'Poppins', Helvetica, Arial, Verdana, sans-serif;
  font-weight: 700;
}


// Body

p {
  line-height: 1.75em;
  margin-bottom: 1.75em;
}

strong {
  font-weight: 700;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit; font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: underline;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover, &:focus {
    color: $linkColorHover;
  }

  &:focus, &:active {
    outline: 0;
  }

  &.tdn {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  .gt-arrow {
    display: inline-block;
  }

  .lt-arrow {
    display: inline-block;
  }

  &.primary-link {
    color: $accent;

    &:hover {
      color: $accentHover;
    }
  }
}

a[href^=tel]{
  text-decoration:none;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

a:not([href]) {
  color: $black; text-decoration: none;
  &:hover, &:focus {
  }
}

img {
  max-width: 100%; height: auto; display: inline-block; vertical-align: middle;
}


// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include display-font;
  color: $secondaryAccent;
  line-height: 1.44em;
  margin: 0 0 0.8em;
  font-weight: 400;

  a {
    text-decoration: none;
    outline: 0; font-weight: inherit;
    color: $secondaryAccent;

    &:hover, &:focus {
      color: $secondaryAccentHover;
    }
  }
}

h1, .h1, h2, .h2 {
  position: relative;
  margin-bottom: 35px;

  @media #{$medium} {
    margin-bottom: 55px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    height: 5px;
    width: 50px;
    background-color: $accent;
  }

  &.no-line {
    &:before {
      content: none;
    }
  }
}

h1, .h1 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 22px, 36px);
}

h2, .h2 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 20px, 36px);
}

h3, .h3 {
  @include fluid-type(font-size, $mediumSize, $largeSize, 16px, 20px);
}

h4, .h4 {
  @include fs(16);
}

h5, .h5 {  }

h6, .h6 {  }


// Lists

ul, ol {
  margin: 0 0 1.5em 16px;

  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;

  ul {
    list-style: circle;
  }

  &.lined {
    @extend .unstyled;
    li {
      border-bottom: 1px solid #E3E3E3; padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0; border-bottom: none;
      }
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled, nav ul {
  margin: 0 0 1.5em; padding: 0; list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none; list-style-image: none;
    padding: 0; background: none;
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt, dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}


// Misc

hr {
  display: block; height: 1px;
  border: 0; border-top: 1px solid $gray; margin: 50px 0;
}

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid $gray;
  margin-bottom: 1.5em;
  @include fs(20);
  font-style: italic;

  p {
    margin-bottom: 0.5em;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    padding-left: 40px;
  }
}
