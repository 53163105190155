/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */
/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */
/* =============================================================================
   IMPORTS
   ========================================================================== */
/*============================================================================
  RESET

  Adapted version of:
  - Eric Meyer reset
  - Boilerplate Normalize.css
  - Normalize opentype
==============================================================================*/
html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html, body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del,
dfn, em, img, q, s, samp, small, strike, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset,
form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, time, section, main {
  display: block;
}

*, *:before, *:after {
  box-sizing: border-box;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
}

a:focus {
  outline: thin dotted;
}

ol, ul {
  list-style: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

figure {
  margin: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

abbr {
  text-transform: uppercase;
}

time {
  display: block;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

/*============================================================================
  VARIABLES
==============================================================================*/
@media (min-width: 480px) {
  .init-mediaqueries {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .init-mediaqueries {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .init-mediaqueries {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .init-mediaqueries {
    margin: 0;
  }
}

@media (min-width: 1400px) {
  .init-mediaqueries {
    margin: 0;
  }
}

@media (min-width: 1680px) {
  .init-mediaqueries {
    margin: 0;
  }
}

/*============================================================================
  MIXINS
==============================================================================*/
.clearfix {
  *zoom: 1;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: " ";
  height: auto;
}

.clearfix:after {
  clear: both;
}

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

@media (max-width: 766px) {
  .hidden--mobile {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden--tablet {
    display: none !important;
  }
}

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.vh.focusable:active, .vh.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

/*============================================================================
  GRID
==============================================================================*/
.grid, .grid--rev, .grid--full {
  *zoom: 1;
  margin: 0 0 0 -20px;
}

.grid:before, .grid--rev:before, .grid--full:before, .grid:after, .grid--rev:after, .grid--full:after {
  display: table;
  content: " ";
  height: auto;
}

.grid:after, .grid--rev:after, .grid--full:after {
  clear: both;
}

ul.grid, ul.grid--rev, ul.grid--full,
ul.grid-uniform {
  list-style: none;
  padding: 0;
}

.grid__item {
  float: left;
  padding-left: 20px;
  width: 100%;
}

.grid--rev {
  direction: rtl;
  text-align: left;
}

.grid--rev > .grid__item {
  direction: ltr;
  text-align: left;
  float: right;
}

.grid--full {
  margin-left: 0;
}

.grid--full > .grid__item {
  padding-left: 0;
}

.one-whole {
  width: 100%;
}

.one-half {
  width: 50%;
}

.one-third {
  width: 33.333%;
}

.two-thirds {
  width: 66.666%;
}

.one-quarter {
  width: 25%;
}

.two-quarters {
  width: 50%;
}

.three-quarters {
  width: 75%;
}

.one-fifth {
  width: 20%;
}

.two-fifths {
  width: 40%;
}

.three-fifths {
  width: 60%;
}

.four-fifths {
  width: 80%;
}

.one-sixth {
  width: 16.666%;
}

.two-sixths {
  width: 33.333%;
}

.three-sixths {
  width: 50%;
}

.four-sixths {
  width: 66.666%;
}

.five-sixths {
  width: 83.333%;
}

.one-eighth {
  width: 12.5%;
}

.two-eighths {
  width: 25%;
}

.three-eighths {
  width: 37.5%;
}

.four-eighths {
  width: 50%;
}

.five-eighths {
  width: 62.5%;
}

.six-eighths {
  width: 75%;
}

.seven-eighths {
  width: 87.5%;
}

.one-tenth {
  width: 10%;
}

.two-tenths {
  width: 20%;
}

.three-tenths {
  width: 30%;
}

.four-tenths {
  width: 40%;
}

.five-tenths {
  width: 50%;
}

.six-tenths {
  width: 60%;
}

.seven-tenths {
  width: 70%;
}

.eight-tenths {
  width: 80%;
}

.nine-tenths {
  width: 90%;
}

.one-twelfth {
  width: 8.333%;
}

.two-twelfths {
  width: 16.666%;
}

.three-twelfths {
  width: 25%;
}

.four-twelfths {
  width: 33.333%;
}

.five-twelfths {
  width: 41.666%;
}

.six-twelfths {
  width: 50%;
}

.seven-twelfths {
  width: 58.333%;
}

.eight-twelfths {
  width: 66.666%;
}

.nine-twelfths {
  width: 75%;
}

.ten-twelfths {
  width: 83.333%;
}

.eleven-twelfths {
  width: 91.666%;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

@media only screen and (min-width: 480px) {
  .small--one-whole {
    width: 100%;
  }
  .small--one-half {
    width: 50%;
  }
  .small--one-third {
    width: 33.333%;
  }
  .small--two-thirds {
    width: 66.666%;
  }
  .small--one-quarter {
    width: 25%;
  }
  .small--two-quarters {
    width: 50%;
  }
  .small--three-quarters {
    width: 75%;
  }
  .small--one-fifth {
    width: 20%;
  }
  .small--two-fifths {
    width: 40%;
  }
  .small--three-fifths {
    width: 60%;
  }
  .small--four-fifths {
    width: 80%;
  }
  .small--one-sixth {
    width: 16.666%;
  }
  .small--two-sixths {
    width: 33.333%;
  }
  .small--three-sixths {
    width: 50%;
  }
  .small--four-sixths {
    width: 66.666%;
  }
  .small--five-sixths {
    width: 83.333%;
  }
  .small--one-eighth {
    width: 12.5%;
  }
  .small--two-eighths {
    width: 25%;
  }
  .small--three-eighths {
    width: 37.5%;
  }
  .small--four-eighths {
    width: 50%;
  }
  .small--five-eighths {
    width: 62.5%;
  }
  .small--six-eighths {
    width: 75%;
  }
  .small--seven-eighths {
    width: 87.5%;
  }
  .small--one-tenth {
    width: 10%;
  }
  .small--two-tenths {
    width: 20%;
  }
  .small--three-tenths {
    width: 30%;
  }
  .small--four-tenths {
    width: 40%;
  }
  .small--five-tenths {
    width: 50%;
  }
  .small--six-tenths {
    width: 60%;
  }
  .small--seven-tenths {
    width: 70%;
  }
  .small--eight-tenths {
    width: 80%;
  }
  .small--nine-tenths {
    width: 90%;
  }
  .small--one-twelfth {
    width: 8.333%;
  }
  .small--two-twelfths {
    width: 16.666%;
  }
  .small--three-twelfths {
    width: 25%;
  }
  .small--four-twelfths {
    width: 33.333%;
  }
  .small--five-twelfths {
    width: 41.666%;
  }
  .small--six-twelfths {
    width: 50%;
  }
  .small--seven-twelfths {
    width: 58.333%;
  }
  .small--eight-twelfths {
    width: 66.666%;
  }
  .small--nine-twelfths {
    width: 75%;
  }
  .small--ten-twelfths {
    width: 83.333%;
  }
  .small--eleven-twelfths {
    width: 91.666%;
  }
  .small--show {
    display: block !important;
  }
  .small--hide {
    display: none !important;
  }
  .small--text-left {
    text-align: left !important;
  }
  .small--text-right {
    text-align: right !important;
  }
  .small--text-center {
    text-align: center !important;
  }
  .small--left {
    float: left !important;
  }
  .small--right {
    float: right !important;
  }
}

@media only screen and (min-width: 768px) {
  .medium--one-whole {
    width: 100%;
  }
  .medium--one-half {
    width: 50%;
  }
  .medium--one-third {
    width: 33.333%;
  }
  .medium--two-thirds {
    width: 66.666%;
  }
  .medium--one-quarter {
    width: 25%;
  }
  .medium--two-quarters {
    width: 50%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-fifth {
    width: 20%;
  }
  .medium--two-fifths {
    width: 40%;
  }
  .medium--three-fifths {
    width: 60%;
  }
  .medium--four-fifths {
    width: 80%;
  }
  .medium--one-sixth {
    width: 16.666%;
  }
  .medium--two-sixths {
    width: 33.333%;
  }
  .medium--three-sixths {
    width: 50%;
  }
  .medium--four-sixths {
    width: 66.666%;
  }
  .medium--five-sixths {
    width: 83.333%;
  }
  .medium--one-eighth {
    width: 12.5%;
  }
  .medium--two-eighths {
    width: 25%;
  }
  .medium--three-eighths {
    width: 37.5%;
  }
  .medium--four-eighths {
    width: 50%;
  }
  .medium--five-eighths {
    width: 62.5%;
  }
  .medium--six-eighths {
    width: 75%;
  }
  .medium--seven-eighths {
    width: 87.5%;
  }
  .medium--one-tenth {
    width: 10%;
  }
  .medium--two-tenths {
    width: 20%;
  }
  .medium--three-tenths {
    width: 30%;
  }
  .medium--four-tenths {
    width: 40%;
  }
  .medium--five-tenths {
    width: 50%;
  }
  .medium--six-tenths {
    width: 60%;
  }
  .medium--seven-tenths {
    width: 70%;
  }
  .medium--eight-tenths {
    width: 80%;
  }
  .medium--nine-tenths {
    width: 90%;
  }
  .medium--one-twelfth {
    width: 8.333%;
  }
  .medium--two-twelfths {
    width: 16.666%;
  }
  .medium--three-twelfths {
    width: 25%;
  }
  .medium--four-twelfths {
    width: 33.333%;
  }
  .medium--five-twelfths {
    width: 41.666%;
  }
  .medium--six-twelfths {
    width: 50%;
  }
  .medium--seven-twelfths {
    width: 58.333%;
  }
  .medium--eight-twelfths {
    width: 66.666%;
  }
  .medium--nine-twelfths {
    width: 75%;
  }
  .medium--ten-twelfths {
    width: 83.333%;
  }
  .medium--eleven-twelfths {
    width: 91.666%;
  }
  .medium--show {
    display: block !important;
  }
  .medium--hide {
    display: none !important;
  }
  .medium--text-left {
    text-align: left !important;
  }
  .medium--text-right {
    text-align: right !important;
  }
  .medium--text-center {
    text-align: center !important;
  }
  .medium--left {
    float: left !important;
  }
  .medium--right {
    float: right !important;
  }
}

@media only screen and (min-width: 1024px) {
  .large--one-whole {
    width: 100%;
  }
  .large--one-half {
    width: 50%;
  }
  .large--one-third {
    width: 33.333%;
  }
  .large--two-thirds {
    width: 66.666%;
  }
  .large--one-quarter {
    width: 25%;
  }
  .large--two-quarters {
    width: 50%;
  }
  .large--three-quarters {
    width: 75%;
  }
  .large--one-fifth {
    width: 20%;
  }
  .large--two-fifths {
    width: 40%;
  }
  .large--three-fifths {
    width: 60%;
  }
  .large--four-fifths {
    width: 80%;
  }
  .large--one-sixth {
    width: 16.666%;
  }
  .large--two-sixths {
    width: 33.333%;
  }
  .large--three-sixths {
    width: 50%;
  }
  .large--four-sixths {
    width: 66.666%;
  }
  .large--five-sixths {
    width: 83.333%;
  }
  .large--one-eighth {
    width: 12.5%;
  }
  .large--two-eighths {
    width: 25%;
  }
  .large--three-eighths {
    width: 37.5%;
  }
  .large--four-eighths {
    width: 50%;
  }
  .large--five-eighths {
    width: 62.5%;
  }
  .large--six-eighths {
    width: 75%;
  }
  .large--seven-eighths {
    width: 87.5%;
  }
  .large--one-tenth {
    width: 10%;
  }
  .large--two-tenths {
    width: 20%;
  }
  .large--three-tenths {
    width: 30%;
  }
  .large--four-tenths {
    width: 40%;
  }
  .large--five-tenths {
    width: 50%;
  }
  .large--six-tenths {
    width: 60%;
  }
  .large--seven-tenths {
    width: 70%;
  }
  .large--eight-tenths {
    width: 80%;
  }
  .large--nine-tenths {
    width: 90%;
  }
  .large--one-twelfth {
    width: 8.333%;
  }
  .large--two-twelfths {
    width: 16.666%;
  }
  .large--three-twelfths {
    width: 25%;
  }
  .large--four-twelfths {
    width: 33.333%;
  }
  .large--five-twelfths {
    width: 41.666%;
  }
  .large--six-twelfths {
    width: 50%;
  }
  .large--seven-twelfths {
    width: 58.333%;
  }
  .large--eight-twelfths {
    width: 66.666%;
  }
  .large--nine-twelfths {
    width: 75%;
  }
  .large--ten-twelfths {
    width: 83.333%;
  }
  .large--eleven-twelfths {
    width: 91.666%;
  }
  .large--show {
    display: block !important;
  }
  .large--hide {
    display: none !important;
  }
  .large--text-left {
    text-align: left !important;
  }
  .large--text-right {
    text-align: right !important;
  }
  .large--text-center {
    text-align: center !important;
  }
  .large--left {
    float: left !important;
  }
  .large--right {
    float: right !important;
  }
}

@media only screen and (min-width: 1200px) {
  .larger--one-whole {
    width: 100%;
  }
  .larger--one-half {
    width: 50%;
  }
  .larger--one-third {
    width: 33.333%;
  }
  .larger--two-thirds {
    width: 66.666%;
  }
  .larger--one-quarter {
    width: 25%;
  }
  .larger--two-quarters {
    width: 50%;
  }
  .larger--three-quarters {
    width: 75%;
  }
  .larger--one-fifth {
    width: 20%;
  }
  .larger--two-fifths {
    width: 40%;
  }
  .larger--three-fifths {
    width: 60%;
  }
  .larger--four-fifths {
    width: 80%;
  }
  .larger--one-sixth {
    width: 16.666%;
  }
  .larger--two-sixths {
    width: 33.333%;
  }
  .larger--three-sixths {
    width: 50%;
  }
  .larger--four-sixths {
    width: 66.666%;
  }
  .larger--five-sixths {
    width: 83.333%;
  }
  .larger--one-eighth {
    width: 12.5%;
  }
  .larger--two-eighths {
    width: 25%;
  }
  .larger--three-eighths {
    width: 37.5%;
  }
  .larger--four-eighths {
    width: 50%;
  }
  .larger--five-eighths {
    width: 62.5%;
  }
  .larger--six-eighths {
    width: 75%;
  }
  .larger--seven-eighths {
    width: 87.5%;
  }
  .larger--one-tenth {
    width: 10%;
  }
  .larger--two-tenths {
    width: 20%;
  }
  .larger--three-tenths {
    width: 30%;
  }
  .larger--four-tenths {
    width: 40%;
  }
  .larger--five-tenths {
    width: 50%;
  }
  .larger--six-tenths {
    width: 60%;
  }
  .larger--seven-tenths {
    width: 70%;
  }
  .larger--eight-tenths {
    width: 80%;
  }
  .larger--nine-tenths {
    width: 90%;
  }
  .larger--one-twelfth {
    width: 8.333%;
  }
  .larger--two-twelfths {
    width: 16.666%;
  }
  .larger--three-twelfths {
    width: 25%;
  }
  .larger--four-twelfths {
    width: 33.333%;
  }
  .larger--five-twelfths {
    width: 41.666%;
  }
  .larger--six-twelfths {
    width: 50%;
  }
  .larger--seven-twelfths {
    width: 58.333%;
  }
  .larger--eight-twelfths {
    width: 66.666%;
  }
  .larger--nine-twelfths {
    width: 75%;
  }
  .larger--ten-twelfths {
    width: 83.333%;
  }
  .larger--eleven-twelfths {
    width: 91.666%;
  }
  .larger--show {
    display: block !important;
  }
  .larger--hide {
    display: none !important;
  }
  .larger--text-left {
    text-align: left !important;
  }
  .larger--text-right {
    text-align: right !important;
  }
  .larger--text-center {
    text-align: center !important;
  }
  .larger--left {
    float: left !important;
  }
  .larger--right {
    float: right !important;
  }
}

[class*="push--"] {
  position: relative;
}

.push--one-whole {
  left: 100%;
  position: relative;
}

.push--one-half {
  left: 50%;
  position: relative;
}

.push--one-third {
  left: 33.333%;
  position: relative;
}

.push--two-thirds {
  left: 66.666%;
  position: relative;
}

.push--one-quarter {
  left: 25%;
  position: relative;
}

.push--two-quarters {
  left: 50%;
  position: relative;
}

.push--three-quarters {
  left: 75%;
  position: relative;
}

.push--one-fifth {
  left: 20%;
  position: relative;
}

.push--two-fifths {
  left: 40%;
  position: relative;
}

.push--three-fifths {
  left: 60%;
  position: relative;
}

.push--four-fifths {
  left: 80%;
  position: relative;
}

.push--one-sixth {
  left: 16.666%;
  position: relative;
}

.push--two-sixths {
  left: 33.333%;
  position: relative;
}

.push--three-sixths {
  left: 50%;
  position: relative;
}

.push--four-sixths {
  left: 66.666%;
  position: relative;
}

.push--five-sixths {
  left: 83.333%;
  position: relative;
}

.push--one-eighth {
  left: 12.5%;
  position: relative;
}

.push--two-eighths {
  left: 25%;
  position: relative;
}

.push--three-eighths {
  left: 37.5%;
  position: relative;
}

.push--four-eighths {
  left: 50%;
  position: relative;
}

.push--five-eighths {
  left: 62.5%;
  position: relative;
}

.push--six-eighths {
  left: 75%;
  position: relative;
}

.push--seven-eighths {
  left: 87.5%;
  position: relative;
}

.push--one-tenth {
  left: 10%;
  position: relative;
}

.push--two-tenths {
  left: 20%;
  position: relative;
}

.push--three-tenths {
  left: 30%;
  position: relative;
}

.push--four-tenths {
  left: 40%;
  position: relative;
}

.push--five-tenths {
  left: 50%;
  position: relative;
}

.push--six-tenths {
  left: 60%;
  position: relative;
}

.push--seven-tenths {
  left: 70%;
  position: relative;
}

.push--eight-tenths {
  left: 80%;
  position: relative;
}

.push--nine-tenths {
  left: 90%;
  position: relative;
}

.push--one-twelfth {
  left: 8.333%;
  position: relative;
}

.push--two-twelfths {
  left: 16.666%;
  position: relative;
}

.push--three-twelfths {
  left: 25%;
  position: relative;
}

.push--four-twelfths {
  left: 33.333%;
  position: relative;
}

.push--five-twelfths {
  left: 41.666%;
  position: relative;
}

.push--six-twelfths {
  left: 50%;
  position: relative;
}

.push--seven-twelfths {
  left: 58.333%;
  position: relative;
}

.push--eight-twelfths {
  left: 66.666%;
  position: relative;
}

.push--nine-twelfths {
  left: 75%;
  position: relative;
}

.push--ten-twelfths {
  left: 83.333%;
  position: relative;
}

.push--eleven-twelfths {
  left: 91.666%;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .push--medium--one-whole {
    left: 100%;
    position: relative;
  }
  .push--medium--one-half {
    left: 50%;
    position: relative;
  }
  .push--medium--one-third {
    left: 33.333%;
    position: relative;
  }
  .push--medium--two-thirds {
    left: 66.666%;
    position: relative;
  }
  .push--medium--one-quarter {
    left: 25%;
    position: relative;
  }
  .push--medium--two-quarters {
    left: 50%;
    position: relative;
  }
  .push--medium--three-quarters {
    left: 75%;
    position: relative;
  }
  .push--medium--one-fifth {
    left: 20%;
    position: relative;
  }
  .push--medium--two-fifths {
    left: 40%;
    position: relative;
  }
  .push--medium--three-fifths {
    left: 60%;
    position: relative;
  }
  .push--medium--four-fifths {
    left: 80%;
    position: relative;
  }
  .push--medium--one-sixth {
    left: 16.666%;
    position: relative;
  }
  .push--medium--two-sixths {
    left: 33.333%;
    position: relative;
  }
  .push--medium--three-sixths {
    left: 50%;
    position: relative;
  }
  .push--medium--four-sixths {
    left: 66.666%;
    position: relative;
  }
  .push--medium--five-sixths {
    left: 83.333%;
    position: relative;
  }
  .push--medium--one-eighth {
    left: 12.5%;
    position: relative;
  }
  .push--medium--two-eighths {
    left: 25%;
    position: relative;
  }
  .push--medium--three-eighths {
    left: 37.5%;
    position: relative;
  }
  .push--medium--four-eighths {
    left: 50%;
    position: relative;
  }
  .push--medium--five-eighths {
    left: 62.5%;
    position: relative;
  }
  .push--medium--six-eighths {
    left: 75%;
    position: relative;
  }
  .push--medium--seven-eighths {
    left: 87.5%;
    position: relative;
  }
  .push--medium--one-tenth {
    left: 10%;
    position: relative;
  }
  .push--medium--two-tenths {
    left: 20%;
    position: relative;
  }
  .push--medium--three-tenths {
    left: 30%;
    position: relative;
  }
  .push--medium--four-tenths {
    left: 40%;
    position: relative;
  }
  .push--medium--five-tenths {
    left: 50%;
    position: relative;
  }
  .push--medium--six-tenths {
    left: 60%;
    position: relative;
  }
  .push--medium--seven-tenths {
    left: 70%;
    position: relative;
  }
  .push--medium--eight-tenths {
    left: 80%;
    position: relative;
  }
  .push--medium--nine-tenths {
    left: 90%;
    position: relative;
  }
  .push--medium--one-twelfth {
    left: 8.333%;
    position: relative;
  }
  .push--medium--two-twelfths {
    left: 16.666%;
    position: relative;
  }
  .push--medium--three-twelfths {
    left: 25%;
    position: relative;
  }
  .push--medium--four-twelfths {
    left: 33.333%;
    position: relative;
  }
  .push--medium--five-twelfths {
    left: 41.666%;
    position: relative;
  }
  .push--medium--six-twelfths {
    left: 50%;
    position: relative;
  }
  .push--medium--seven-twelfths {
    left: 58.333%;
    position: relative;
  }
  .push--medium--eight-twelfths {
    left: 66.666%;
    position: relative;
  }
  .push--medium--nine-twelfths {
    left: 75%;
    position: relative;
  }
  .push--medium--ten-twelfths {
    left: 83.333%;
    position: relative;
  }
  .push--medium--eleven-twelfths {
    left: 91.666%;
    position: relative;
  }
}

@media only screen and (min-width: 1024px) {
  .push--large--one-whole {
    left: 100%;
    position: relative;
  }
  .push--large--one-half {
    left: 50%;
    position: relative;
  }
  .push--large--one-third {
    left: 33.333%;
    position: relative;
  }
  .push--large--two-thirds {
    left: 66.666%;
    position: relative;
  }
  .push--large--one-quarter {
    left: 25%;
    position: relative;
  }
  .push--large--two-quarters {
    left: 50%;
    position: relative;
  }
  .push--large--three-quarters {
    left: 75%;
    position: relative;
  }
  .push--large--one-fifth {
    left: 20%;
    position: relative;
  }
  .push--large--two-fifths {
    left: 40%;
    position: relative;
  }
  .push--large--three-fifths {
    left: 60%;
    position: relative;
  }
  .push--large--four-fifths {
    left: 80%;
    position: relative;
  }
  .push--large--one-sixth {
    left: 16.666%;
    position: relative;
  }
  .push--large--two-sixths {
    left: 33.333%;
    position: relative;
  }
  .push--large--three-sixths {
    left: 50%;
    position: relative;
  }
  .push--large--four-sixths {
    left: 66.666%;
    position: relative;
  }
  .push--large--five-sixths {
    left: 83.333%;
    position: relative;
  }
  .push--large--one-eighth {
    left: 12.5%;
    position: relative;
  }
  .push--large--two-eighths {
    left: 25%;
    position: relative;
  }
  .push--large--three-eighths {
    left: 37.5%;
    position: relative;
  }
  .push--large--four-eighths {
    left: 50%;
    position: relative;
  }
  .push--large--five-eighths {
    left: 62.5%;
    position: relative;
  }
  .push--large--six-eighths {
    left: 75%;
    position: relative;
  }
  .push--large--seven-eighths {
    left: 87.5%;
    position: relative;
  }
  .push--large--one-tenth {
    left: 10%;
    position: relative;
  }
  .push--large--two-tenths {
    left: 20%;
    position: relative;
  }
  .push--large--three-tenths {
    left: 30%;
    position: relative;
  }
  .push--large--four-tenths {
    left: 40%;
    position: relative;
  }
  .push--large--five-tenths {
    left: 50%;
    position: relative;
  }
  .push--large--six-tenths {
    left: 60%;
    position: relative;
  }
  .push--large--seven-tenths {
    left: 70%;
    position: relative;
  }
  .push--large--eight-tenths {
    left: 80%;
    position: relative;
  }
  .push--large--nine-tenths {
    left: 90%;
    position: relative;
  }
  .push--large--one-twelfth {
    left: 8.333%;
    position: relative;
  }
  .push--large--two-twelfths {
    left: 16.666%;
    position: relative;
  }
  .push--large--three-twelfths {
    left: 25%;
    position: relative;
  }
  .push--large--four-twelfths {
    left: 33.333%;
    position: relative;
  }
  .push--large--five-twelfths {
    left: 41.666%;
    position: relative;
  }
  .push--large--six-twelfths {
    left: 50%;
    position: relative;
  }
  .push--large--seven-twelfths {
    left: 58.333%;
    position: relative;
  }
  .push--large--eight-twelfths {
    left: 66.666%;
    position: relative;
  }
  .push--large--nine-twelfths {
    left: 75%;
    position: relative;
  }
  .push--large--ten-twelfths {
    left: 83.333%;
    position: relative;
  }
  .push--large--eleven-twelfths {
    left: 91.666%;
    position: relative;
  }
}

@media only screen and (min-width: 1200px) {
  .push--larger--one-whole {
    left: 100%;
    position: relative;
  }
  .push--larger--one-half {
    left: 50%;
    position: relative;
  }
  .push--larger--one-third {
    left: 33.333%;
    position: relative;
  }
  .push--larger--two-thirds {
    left: 66.666%;
    position: relative;
  }
  .push--larger--one-quarter {
    left: 25%;
    position: relative;
  }
  .push--larger--two-quarters {
    left: 50%;
    position: relative;
  }
  .push--larger--three-quarters {
    left: 75%;
    position: relative;
  }
  .push--larger--one-fifth {
    left: 20%;
    position: relative;
  }
  .push--larger--two-fifths {
    left: 40%;
    position: relative;
  }
  .push--larger--three-fifths {
    left: 60%;
    position: relative;
  }
  .push--larger--four-fifths {
    left: 80%;
    position: relative;
  }
  .push--larger--one-sixth {
    left: 16.666%;
    position: relative;
  }
  .push--larger--two-sixths {
    left: 33.333%;
    position: relative;
  }
  .push--larger--three-sixths {
    left: 50%;
    position: relative;
  }
  .push--larger--four-sixths {
    left: 66.666%;
    position: relative;
  }
  .push--larger--five-sixths {
    left: 83.333%;
    position: relative;
  }
  .push--larger--one-eighth {
    left: 12.5%;
    position: relative;
  }
  .push--larger--two-eighths {
    left: 25%;
    position: relative;
  }
  .push--larger--three-eighths {
    left: 37.5%;
    position: relative;
  }
  .push--larger--four-eighths {
    left: 50%;
    position: relative;
  }
  .push--larger--five-eighths {
    left: 62.5%;
    position: relative;
  }
  .push--larger--six-eighths {
    left: 75%;
    position: relative;
  }
  .push--larger--seven-eighths {
    left: 87.5%;
    position: relative;
  }
  .push--larger--one-tenth {
    left: 10%;
    position: relative;
  }
  .push--larger--two-tenths {
    left: 20%;
    position: relative;
  }
  .push--larger--three-tenths {
    left: 30%;
    position: relative;
  }
  .push--larger--four-tenths {
    left: 40%;
    position: relative;
  }
  .push--larger--five-tenths {
    left: 50%;
    position: relative;
  }
  .push--larger--six-tenths {
    left: 60%;
    position: relative;
  }
  .push--larger--seven-tenths {
    left: 70%;
    position: relative;
  }
  .push--larger--eight-tenths {
    left: 80%;
    position: relative;
  }
  .push--larger--nine-tenths {
    left: 90%;
    position: relative;
  }
  .push--larger--one-twelfth {
    left: 8.333%;
    position: relative;
  }
  .push--larger--two-twelfths {
    left: 16.666%;
    position: relative;
  }
  .push--larger--three-twelfths {
    left: 25%;
    position: relative;
  }
  .push--larger--four-twelfths {
    left: 33.333%;
    position: relative;
  }
  .push--larger--five-twelfths {
    left: 41.666%;
    position: relative;
  }
  .push--larger--six-twelfths {
    left: 50%;
    position: relative;
  }
  .push--larger--seven-twelfths {
    left: 58.333%;
    position: relative;
  }
  .push--larger--eight-twelfths {
    left: 66.666%;
    position: relative;
  }
  .push--larger--nine-twelfths {
    left: 75%;
    position: relative;
  }
  .push--larger--ten-twelfths {
    left: 83.333%;
    position: relative;
  }
  .push--larger--eleven-twelfths {
    left: 91.666%;
    position: relative;
  }
}

/*============================================================================
  TYPOGRAPHY
==============================================================================*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?ojb00b");
  src: url("../fonts/icomoon.eot?ojb00b#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?ojb00b") format("truetype"), url("../fonts/icomoon.woff?ojb00b") format("woff"), url("../fonts/icomoon.svg?ojb00b#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-exclamation:before {
  content: "\e90f";
}

.icon-search:before {
  content: "\e90e";
}

.icon-close:before {
  content: "\e90d";
}

.icon-plus:before {
  content: "\e90a";
}

.icon-plus-broadcast:before {
  content: "\e904";
}

.icon-plus-microphone:before {
  content: "\e905";
}

.icon-plus-people:before {
  content: "\e906";
}

.icon-plus-pencil:before {
  content: "\e907";
}

.icon-linkedin:before {
  content: "\e900";
}

.icon-instagram:before {
  content: "\e901";
}

.icon-twiter:before {
  content: "\e902";
}

.icon-facebook:before {
  content: "\e903";
}

.icon-newsletter:before {
  content: "\ea86";
}

.icon-arrow-left:before {
  content: "\e908";
}

.icon-arrow-right:before {
  content: "\e909";
}

.icon-clock:before {
  content: "\e90b";
}

.icon-marker:before {
  content: "\e90c";
}

p {
  line-height: 1.75em;
  margin-bottom: 1.75em;
}

strong {
  font-weight: 700;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit;
  font-style: inherit;
  color: #000068;
  transition: 0.15s cubic-bezier(0, 0, 0.58, 1);
  text-decoration: underline;
  text-decoration-skip: ink;
}

a:hover, a:focus {
  color: #0000b5;
}

a:focus, a:active {
  outline: 0;
}

a.tdn {
  text-decoration: none;
}

a.tdn:hover, a.tdn:focus {
  text-decoration: underline;
}

a .gt-arrow {
  display: inline-block;
}

a .lt-arrow {
  display: inline-block;
}

a.primary-link {
  color: #FF5619;
}

a.primary-link:hover {
  color: #e53d00;
}

a[href^=tel] {
  text-decoration: none;
}

a[href^=tel]:hover, a[href^=tel]:focus {
  text-decoration: underline;
}

a:not([href]) {
  color: #4B4B4B;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'Poppins', Helvetica, Arial, Verdana, sans-serif;
  font-weight: 700;
  color: #000068;
  line-height: 1.44em;
  margin: 0 0 0.8em;
  font-weight: 400;
}

h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
  text-decoration: none;
  outline: 0;
  font-weight: inherit;
  color: #000068;
}

h1 a:hover, h1 a:focus, .h1 a:hover, .h1 a:focus, h2 a:hover, h2 a:focus, .h2 a:hover, .h2 a:focus, h3 a:hover, h3 a:focus, .h3 a:hover, .h3 a:focus, h4 a:hover, h4 a:focus, .h4 a:hover, .h4 a:focus, h5 a:hover, h5 a:focus, .h5 a:hover, .h5 a:focus, h6 a:hover, h6 a:focus, .h6 a:hover, .h6 a:focus {
  color: #0000b5;
}

h1, .h1, h2, .h2 {
  position: relative;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  h1, .h1, h2, .h2 {
    margin-bottom: 55px;
  }
}

h1:before, .h1:before, h2:before, .h2:before {
  content: '';
  display: block;
  position: absolute;
  bottom: -10px;
  height: 5px;
  width: 50px;
  background-color: #FF5619;
}

h1.no-line:before, .h1.no-line:before, h2.no-line:before, .h2.no-line:before {
  content: none;
}

h1, .h1 {
  font-size: 22px;
}

@media screen and (min-width: 768px) {
  h1, .h1 {
    font-size: calc(22px + 14 * (100vw - 768px) / 432);
  }
}

@media screen and (min-width: 1200px) {
  h1, .h1 {
    font-size: 36px;
  }
}

h2, .h2 {
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  h2, .h2 {
    font-size: calc(20px + 16 * (100vw - 768px) / 432);
  }
}

@media screen and (min-width: 1200px) {
  h2, .h2 {
    font-size: 36px;
  }
}

h3, .h3 {
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  h3, .h3 {
    font-size: calc(16px + 4 * (100vw - 768px) / 256);
  }
}

@media screen and (min-width: 1024px) {
  h3, .h3 {
    font-size: 20px;
  }
}

h4, .h4 {
  font-size: 16px;
  font-size: 1.6rem;
}

ul, ol {
  margin: 0 0 1.5em 16px;
}

ul li, ol li {
  line-height: 1.5em;
  padding: 0;
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;
}

ul ul {
  list-style: circle;
}

ul.lined li {
  border-bottom: 1px solid #E3E3E3;
  padding: 5px 0;
}

ul.lined li:first-child {
  padding-top: 0;
}

ul.lined li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

ol {
  list-style: decimal;
}

.unstyled, ul.lined, nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none;
}

.unstyled ul, ul.lined ul, nav ul ul {
  list-style: none;
}

.unstyled li, ul.lined li, nav ul li {
  list-style: none;
  list-style-image: none;
  padding: 0;
  background: none;
}

nav ul {
  *zoom: 1;
}

nav ul:before, nav ul:after {
  display: table;
  content: " ";
  height: auto;
}

nav ul:after {
  clear: both;
}

dl {
  margin: 0 0 1.5em;
}

dt, dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #A2A2A2;
  margin: 50px 0;
}

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid #A2A2A2;
  margin-bottom: 1.5em;
  font-size: 20px;
  font-size: 2rem;
  font-style: italic;
}

blockquote p {
  margin-bottom: 0.5em;
}

blockquote cite {
  font-size: 14px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  blockquote {
    padding-left: 40px;
  }
}

/*============================================================================
  TABLES
==============================================================================*/
table {
  width: 100%;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  margin: 0 0 1.5em;
}

table th, table td {
  padding: 11px 6px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
  line-height: 1.5em;
}

table th {
  font-weight: bold;
  border-bottom: 2px solid #ddd;
}

/*============================================================================
  FORMS
==============================================================================*/
form {
  margin: 0;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
  transition: 0.15s cubic-bezier(0, 0, 0.58, 1);
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

label, .label, input, select, textarea {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-family: 'Poppins', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  vertical-align: middle;
  color: #4B4B4B;
}

label, .label {
  display: block;
  margin-bottom: 8px;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 0 14px;
  margin-bottom: 2px;
  display: block;
  background: #fff;
  height: 38px;
  line-height: inherit;
  width: 100%;
  border: 1px solid #EFEFEF;
  transition: all .2s ease-out;
}

input[type="text"].float-label, input[type="search"].float-label, input[type="password"].float-label,
input[type="email"].float-label, input[type="tel"].float-label, input[type="date"].float-label, textarea.float-label, select.float-label {
  outline: 0;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.05);
}

input[type="text"]:focus, input[type="search"]:focus, input[type="password"]:focus,
input[type="email"]:focus, input[type="tel"]:focus, input[type="date"]:focus, textarea:focus, select:focus {
  outline: 0;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

input[type="text"].highlight:not(.float-label):not(:focus), input[type="text"].highlight.valid-vat, input[type="search"].highlight:not(.float-label):not(:focus), input[type="search"].highlight.valid-vat, input[type="password"].highlight:not(.float-label):not(:focus), input[type="password"].highlight.valid-vat,
input[type="email"].highlight:not(.float-label):not(:focus),
input[type="email"].highlight.valid-vat, input[type="tel"].highlight:not(.float-label):not(:focus), input[type="tel"].highlight.valid-vat, input[type="date"].highlight:not(.float-label):not(:focus), input[type="date"].highlight.valid-vat, textarea.highlight:not(.float-label):not(:focus), textarea.highlight.valid-vat, select.highlight:not(.float-label):not(:focus), select.highlight.valid-vat {
  border-color: #FF5619;
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  width: 100%;
  height: 185px;
  padding-top: 18px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 0;
  margin: 4px 0;
  *margin-top: 0;
  *height: 13px;
  *width: 13px;
  line-height: normal;
  border: none;
}

input[type="checkbox"]#gdpr {
  margin: 2px 0;
}

input[type="file"] {
  background-color: #fff;
  padding: initial;
  border: initial;
  line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

button, input[type="reset"], input[type="submit"], .button {
  float: left;
  display: block;
  clear: both;
  transition: 0.15s cubic-bezier(0, 0, 0.58, 1);
  outline: 0;
  line-height: 1.2em;
  padding: 12px 20px;
  margin: 10px 0 0;
  background-color: #FF5619;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
}

button i, input[type="reset"] i, input[type="submit"] i, .button i {
  margin: -5px 6px 0 5px;
}

button:hover, button:focus, button:active, input[type="reset"]:hover, input[type="reset"]:focus, input[type="reset"]:active, input[type="submit"]:hover, input[type="submit"]:focus, input[type="submit"]:active, .button:hover, .button:focus, .button:active {
  background-color: #e53d00;
}

button[disabled], input[type="reset"][disabled], input[type="submit"][disabled], .button[disabled] {
  background-color: #EFEFEF !important;
}

button:active, input[type="reset"]:active, input[type="submit"]:active, .button:active {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
}

button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, .button::-moz-focus-inner {
  border: 0;
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

button:focus, input[type="reset"]:focus, input[type="submit"]:focus, .button:focus, a.button:focus, select:focus {
  outline: 0;
  *outline: inherit;
  box-shadow: 0 0 1px 1px rgba(200, 200, 200, 0.1);
}

::placeholder {
  color: #999;
  font-style: italic;
}

::-ms-clear {
  display: none;
}

input[type="search"] {
  -webkit-appearance: none;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::-webkit-search-results-button {
  -webkit-appearance: none;
}

::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

form .row {
  margin-bottom: 0;
}

fieldset {
  width: auto;
}

fieldset p, fieldset ul, fieldset li {
  *zoom: 1;
}

fieldset p:before, fieldset p:after, fieldset ul:before, fieldset ul:after, fieldset li:before, fieldset li:after {
  display: table;
  content: " ";
  height: auto;
}

fieldset p:after, fieldset ul:after, fieldset li:after {
  clear: both;
}

fieldset ul {
  margin-left: -20px;
}

fieldset ul li {
  list-style: none;
  background: none;
  list-style-image: none;
  margin: 0 0 10px;
  float: left;
  width: 100%;
  padding: 0 0 0 20px;
}

fieldset ul li.error input {
  border-color: #f1062c;
}

fieldset ul li.space {
  margin-top: 25px;
}

@media (min-width: 768px) {
  fieldset ul li.one-half {
    width: 50%;
  }
  fieldset ul li.one-fourth {
    width: 25%;
  }
}

.buttons {
  *zoom: 1;
}

.buttons:before, .buttons:after {
  display: table;
  content: " ";
  height: auto;
}

.buttons:after {
  clear: both;
}

.buttons button, .buttons input[type="reset"], .buttons input[type="submit"], .buttons .button, .buttons a.button {
  float: left;
  clear: none;
  margin-right: 15px;
}

.buttons button.right, .buttons input[type="reset"].right, .buttons input[type="submit"].right, .buttons .button.right, .buttons a.button.right {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}

.a-c .button, .a-c a.button, .a-c button {
  float: none;
  clear: none;
  display: inline-block;
}

fieldset {
  position: relative;
}

.form__item {
  position: relative;
  margin-bottom: 15px;
}

.form__item input:not([type="checkbox"]) + label, .form__item textarea + label {
  position: absolute;
  top: 9px;
  left: 14px;
  color: #A2A2A2;
  transition: all .2s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.form__item input:not([type="checkbox"]):focus ~ label, .form__item input:not([type="checkbox"]).float-label ~ label, .form__item textarea:focus ~ label, .form__item textarea.float-label ~ label {
  top: -6px;
  transform: scale(0.6);
}

.form__required-notifier {
  color: #FF5619;
  position: absolute;
  top: -20px;
  right: 0;
}

.checkbox-statement {
  padding-left: 25px;
  position: relative;
  margin-bottom: 10px;
}

.checkbox-statement input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
}

select.country-select {
  background-color: #fff;
  background-color: red;
}

select.country-select + .select2-container--default .select2-selection--single {
  background-color: #fff;
  height: 38px;
}

select.country-select + .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}

select.country-select + .select2-container--default .select2-selection--single .select2-selection__clear {
  display: none;
}

select.country-select + .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px;
}

label[for="gdpr"] {
  font-size: 13px;
  color: #A2A2A2;
}

label {
  user-select: none;
}

.grecaptcha-badge {
  display: none;
}

.formError {
  z-index: 990;
}

.formError .formErrorContent {
  z-index: 991;
}

.formError .formErrorArrow {
  z-index: 996;
}

.formErrorInsideDialog.formError {
  z-index: 5000;
}

.formErrorInsideDialog.formError .formErrorContent {
  z-index: 5001;
}

.formErrorInsideDialog.formError .formErrorArrow {
  z-index: 5006;
}

.inputContainer {
  position: relative;
  float: left;
}

.formError, .formError * {
  box-sizing: content-box;
}

.formError {
  position: absolute;
  top: 300px;
  left: 300px;
  display: block;
  cursor: pointer;
  margin: 0 0 0 -128px;
}

.ajaxSubmit {
  padding: 20px;
  background: #55ea55;
  border: 1px solid #999;
  display: none;
}

.formError .formErrorContent {
  background: #f1062c;
  position: relative;
  color: #fff;
  width: 150px;
  border: 1px solid #f1062c;
  padding: 4px 10px 4px 10px;
  font-size: 14px;
  font-size: 1.4rem;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.formError .formErrorArrow {
  /*width: 15px; margin: -2px 0 0 13px;*/
  position: relative;
  margin: -2px 0 0 145px;
  height: 10px;
}

.formError .formErrorArrow:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  border: 8px solid transparent;
  border-bottom: 0;
  border-top-color: #f1062c;
}

.formError .formErrorArrowBottom {
  box-shadow: none;
  margin: 0px 0 0 12px;
  top: 2px;
}

.formError .formErrorArrow div {
  display: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transition-delay: .2s !important;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.main .select2-container {
  width: 100% !important;
}

.select2-dropdown {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-selection {
  overflow: hidden;
}

.select2-selection__rendered {
  white-space: normal;
  word-break: break-all;
}

.select2-container--default .select2-selection--single {
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  outline: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000068;
  font-weight: 700;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-left: 15px;
  transform: rotate(0);
  transition: transform .2s ease-out;
}

.select2-container--default .select2-selection--single .select2-selection__clear:hover {
  transform: rotate(90deg);
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #000068;
  font-weight: 700;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #000068 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #000068 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
  position: relative;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-top-color: #000068;
  border-width: 5px 4px 0 4px;
  border-style: solid;
  position: absolute;
  right: 0;
  top: 50%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-search__field {
  border: 0;
  color: #000068;
  font-weight: 700;
}

.select2-container--default .select2-selection--multiple .select2-search__field::placeholder {
  color: #000068;
  font-style: normal;
  font-weight: 700;
  width: 100% !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #000068;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: transparent;
  font-weight: 700;
  border-bottom: 1px solid #000068;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #000068;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 6px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #0000b5;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  outline: 0;
}

.select2-container--default.select2-container--focus .select2-selection--multiple .select2-search__field::placeholder {
  opacity: 0;
}

.select2-container--default.select2-container--focus .select2-selection__rendered:before {
  border-color: transparent;
  border-bottom-color: #000068;
  border-width: 0 4px 5px 4px;
  border-style: solid;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 0;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results {
  min-width: 200px;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #e3e4ef;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #000068;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.iw-container {
  position: relative !important;
}

@media (min-width: 768px) {
  .iw-container {
    max-width: 286px !important;
    min-width: 250px !important;
  }
}

.iw-container .visual {
  padding-top: 46.8%;
}

.gm-style, .gm-style-iw {
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

.gm-style-iw > div:first-of-type {
  display: block !important;
  width: 100% !important;
  max-width: 100% !important;
  max-height: none !important;
}

.iw-content__inner {
  padding: 18px 18px 30px 18px;
  color: #767676;
  font-weight: 400;
}

.iw-content__title {
  color: #000068;
  font-size: 14px;
  font-weight: 600;
}

/* =============================================================================
   LAYOUT
   ========================================================================== */
html {
  font-size: 62.5%;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-family: 'Poppins', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  color: #4B4B4B;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

body.calendar, body.workshop-register, body.news {
  background-color: #F4FCFF;
}

.page-wrap {
  position: relative;
  overflow-x: hidden;
  pointer-events: none;
}

.page-wrap > * {
  pointer-events: auto;
}

@media (min-width: 768px) {
  .page-wrap {
    min-height: 100vh;
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (min-width: 1024px) {
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .container {
    padding-left: 75px;
    padding-right: 75px;
  }
}

.wrapper--small {
  max-width: 600px;
}

.wf-loading {
  overflow: hidden;
}

.wf-loading body {
  visibility: hidden;
}

::selection {
  background-color: #ffece5;
  color: inherit;
}

/* =============================================================================
   HELPERS
   ========================================================================== */
.icon {
  display: inline-block;
  vertical-align: middle;
  transition: 0.15s cubic-bezier(0, 0, 0.58, 1);
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 18px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 2px solid #FF5619;
  transition: 0.15s cubic-bezier(0, 0, 0.58, 1);
}

.icon-hamburger {
  position: relative;
}

.icon-hamburger:before, .icon-hamburger:after {
  content: "";
  margin-left: 0;
}

.icon-hamburger:before {
  margin-top: 3px;
}

.icon-hamburger:after {
  margin-top: 8px;
}

.gm-style img {
  max-width: inherit;
}

.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.equal-h {
  display: flex;
  flex-wrap: wrap;
}

.equal-h > * {
  display: flex;
}

.equal-h:before, .equal-h:after {
  display: none;
}

html.no-flexbox .equal-h {
  height: 100%;
}

html.no-flexbox .equal-h > div {
  float: left;
}

.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* =============================================================================
   HEADER
   ========================================================================== */
.page-header {
  *zoom: 1;
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999999;
}

.page-header:before, .page-header:after {
  display: table;
  content: " ";
  height: auto;
}

.page-header:after {
  clear: both;
}

@media (min-width: 768px) {
  .page-header {
    padding: 0 0 25px;
  }
}

.page-header__logo {
  text-decoration: none;
  opacity: 1;
  backface-visibility: hidden;
}

@media (min-width: 768px) {
  .page-header__logo {
    float: left;
    padding-top: 39px;
  }
}

.page-header__logo img {
  display: block;
}

@media (min-width: 768px) {
  .page-header__logo img {
    margin-bottom: 0;
  }
}

@media (max-width: 1000px) {
  .page-header__logo img {
    width: 120px;
    margin-top: 20px;
  }
}

@media (max-width: 766px) {
  .page-header__logo img {
    width: 140px;
    margin-top: 0;
  }
}

.page-header__logo:hover, .page-header__logo:focus {
  opacity: 0.8;
  backface-visibility: hidden;
}

.main-nav {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.main-nav li:not(.main-nav__socials) a {
  text-decoration: none;
  color: #000068;
  padding: 12px 15px;
  display: block;
  border-top: 1px solid #F4F4F4;
}

.main-nav li:last-of-type a {
  border-bottom: 1px solid #F4F4F4;
}

.main-nav li.active:before,
.main-nav li:hover:before {
  width: 26px !important;
}

.main-nav a:hover,
.main-nav a:focus,
.main-nav li.active a {
  color: #FF5619;
}

.main-nav li.main-nav__socials {
  text-align: center;
  border-top: 1px solid #F4F4F4;
  padding: 10px 0;
  font-size: 18px;
}

.main-nav li.main-nav__socials a {
  display: inline-block;
  text-decoration: none;
  margin: 0 10px;
  color: #FF5619;
}

.main-nav li.main-nav__socials a:hover, .main-nav li.main-nav__socials a:focus {
  color: #e53d00;
}

@media (min-width: 768px) {
  .main-nav li.main-nav__socials {
    display: none;
  }
}

@media (min-width: 768px) {
  .main-nav {
    margin-top: 87px;
    float: right;
  }
  .main-nav li:not(.main-nav__socials) {
    float: left;
    margin: 0 0 0 20px;
    position: relative;
  }
  .main-nav li:not(.main-nav__socials):before {
    content: "";
    display: block;
    position: absolute;
    bottom: -11px;
    left: 0;
    height: 3px;
    width: 0;
    transition: width 0.15s cubic-bezier(0, 0, 0.58, 1);
    background-color: #FF5619;
  }
  .main-nav li:not(.main-nav__socials) a {
    border-bottom: 0;
    border-top: 0;
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .main-nav li {
    margin-left: 45px;
  }
}

.main-nav__trigger {
  display: block;
  padding: 14px 20px 11px 50px;
  background-color: transparent;
  color: #4B4B4B;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  top: 1px;
  right: 20px;
  border: 1px solid #efefef;
  margin-top: 0;
}

.main-nav__trigger .icon-hamburger {
  position: absolute;
  left: 12px;
  top: 19px;
}

.main-nav__trigger:hover, .main-nav__trigger:focus {
  background-color: transparent;
  box-shadow: none;
}

.main-nav__trigger:active {
  transform: none;
}

@media (min-width: 768px) {
  .main-nav__trigger {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0;
}

@media (max-width: 766px) {
  .main-nav__wrap {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url("../images/mobile-bg.png");
    background-repeat: repeat;
    text-align: center;
    padding-top: 100px;
  }
}

@media (min-width: 768px) {
  .main-nav__wrap {
    margin: 0 0 0 -20px;
    display: block;
  }
}

.page-header__socials-wrapper {
  float: right;
  margin-top: 46px;
  margin-left: 35px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.page-header__socials-wrapper a {
  color: #FF5619;
  text-decoration: none;
}

.page-header__socials-wrapper a:hover {
  color: #e53d00;
}

.page-header__socials-wrapper .icon-newsletter {
  font-size: 25px;
  margin-right: 5px;
}

.page-header__socials-wrapper .smaller {
  font-size: 13px;
}

@media (min-width: 1200px) {
  .page-header__socials-wrapper {
    margin-left: 75px;
  }
}

.page-header__socials,
.insta-link {
  display: none;
  list-style: none;
}

.page-header__socials li,
.insta-link li {
  float: left;
  font-size: 25px;
  margin-right: 5px;
}

.page-header__socials li:last-of-type,
.insta-link li:last-of-type {
  margin-right: 0;
}

.page-header__socials a,
.insta-link a {
  color: #FF5619;
  text-decoration: none;
}

.page-header__socials a:hover,
.insta-link a:hover {
  color: #e53d00;
}

@media (min-width: 768px) {
  .page-header__socials,
  .insta-link {
    display: block;
  }
}

.insta-link {
  margin-left: 0;
  margin-top: 0;
  float: left;
  display: block !important;
}

.insta-link a {
  display: flex;
}

.insta-link a span {
  margin-left: 5px;
  font-family: "Poppins", Helvetica, Arial, Verdana, sans-serif;
  font-size: 14px;
  padding-top: 5px;
  line-height: 1;
}

.cc-color-override-530831885 .cc-btn {
  background: #FF5619 !important;
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;
}

.skip-link:focus {
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  padding: 0.5em 1em;
  font-size: 20px;
  font-size: 2rem;
}

.visual {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 66.66%;
}

.visual:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  z-index: 1;
  transition: background-color 0.4s ease-out;
}

.visual.yellow:before {
  background-color: #F4C500;
}

.visual.red:before {
  background-color: #FF5619;
}

.visual.blue:before {
  background-color: #72C8ED;
}

.visual.green:before {
  background-color: #76C100;
}

.visual__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.visual--square {
  padding-top: 100%;
}

.visual--banner {
  padding-top: 21.25%;
}

.visual--banner-aanbod {
  display: none;
  position: relative;
}

.visual--banner-aanbod .visual__img {
  background-position: 0 10%;
}

@media (min-width: 768px) {
  .visual--banner-aanbod {
    display: block;
  }
}

.visual--team-member {
  padding-top: 78.3%;
}

/* =============================================================================
   MAIN
   ========================================================================== */
.main {
  padding-top: 100px;
  pointer-events: none;
}

.main > * {
  pointer-events: auto;
}

@media (min-width: 768px) {
  .main {
    padding: 132px 0 476px;
    min-height: 100vh;
  }
}

/* =============================================================================
   FOOTER
   ========================================================================== */
.page-footer {
  position: relative;
  padding-top: 40px;
  padding-bottom: 15px;
}

.page-footer a {
  color: #4B4B4B;
}

.page-footer .grid, .page-footer .grid--rev, .page-footer .grid--full {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .page-footer {
    height: 476px;
    margin: -476px 0 0;
    padding-top: 60px;
    background-image: url("../images/footer-bg.png");
    background-size: 120% 90%;
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}

.footer__logo {
  margin: 0 auto 40px auto;
}

@media (min-width: 768px) {
  .footer__logo {
    margin-bottom: 40px;
  }
}

.page-footer__top-content {
  text-align: center;
}

.page-footer__contact-info {
  margin-bottom: 35px;
}

.page-footer__socials {
  display: inline-block;
  margin-bottom: 10px;
}

.page-footer__socials ul {
  list-style: none;
  margin: 0;
  *zoom: 1;
}

.page-footer__socials ul:before, .page-footer__socials ul:after {
  display: table;
  content: " ";
  height: auto;
}

.page-footer__socials ul:after {
  clear: both;
}

.page-footer__socials li {
  float: left;
  font-size: 30px;
  margin: 0 5px;
}

.page-footer__socials a {
  color: #FF5619;
  transition-duration: 0.1s;
}

.page-footer__socials a:hover {
  color: #e53d00;
}

.page-footer__bottom-content {
  font-size: 14px;
  text-align: center;
}

.page-footer__bottom-content,
.page-footer__bottom-content a {
  color: #A2A2A2;
}

@media (min-width: 768px) {
  .page-footer__bottom-content {
    text-align: left;
  }
}

.page-footer__links {
  display: inline-block;
  margin: 30px 0;
}

.page-footer__links a {
  display: block;
  margin: 0 auto;
  line-height: 1.5em;
}

@media (min-width: 768px) {
  .page-footer__links a {
    display: inline-block;
    margin-right: 15px;
    margin-left: 15px;
  }
  .page-footer__links a:first-of-type {
    margin-left: 0;
  }
  .page-footer__links a:last-of-type {
    margin-right: 0;
  }
}

.page-footer__links span {
  display: none;
}

@media (min-width: 768px) {
  .page-footer__links span {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .page-footer__links {
    margin: 0;
  }
}

.certified-by {
  display: block;
}

@media (min-width: 768px) {
  .certified-by {
    display: inline-block;
    width: 33%;
  }
}

.certification-image {
  margin-left: 20px;
}

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
}

a.esign:hover, a.esign:focus {
  opacity: 1;
}

@media (min-width: 768px) {
  a.esign {
    width: 16px;
    position: absolute;
    right: 45px;
    bottom: 0;
  }
  a.esign:hover, a.esign:focus {
    opacity: 1;
    width: 80px;
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */
.large {
  font-size: 20px;
  font-size: 2rem;
}

.center-block {
  *zoom: 1;
  text-align: center;
  margin: 20px 0;
}

.center-block:before, .center-block:after {
  display: table;
  content: " ";
  height: auto;
}

.center-block:after {
  clear: both;
}

.center-block .button {
  float: none;
  display: inline-block;
}

section {
  position: relative;
}

article {
  *zoom: 1;
  margin: 0 0 20px;
}

article:before, article:after {
  display: table;
  content: " ";
  height: auto;
}

article:after {
  clear: both;
}

article a img {
  display: block;
  opacity: 1;
  backface-visibility: hidden;
}

article a:hover img, article a:focus img {
  opacity: 0.8;
  backface-visibility: hidden;
}

.article__author {
  font-size: 14px;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .article__author {
    margin-bottom: 50px;
  }
}

.article__author a {
  color: #FF5619;
}

.article__author a:hover {
  color: #e53d00;
}

.article-detail__image {
  margin-bottom: 1.5em;
}

.note {
  *zoom: 1;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;
}

.note:before, .note:after {
  display: table;
  content: " ";
  height: auto;
}

.note:after {
  clear: both;
}

.note ul,
.note p {
  margin-bottom: 1em;
}

.note--success {
  color: #39963a;
  background-color: #eff9ef;
}

.note--error {
  color: #f1062c;
  background-color: #fff7f8;
}

.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;
}

.cycle-pager span {
  display: inline-block;
  margin: 0 3px;
  font-size: 36px;
  height: 20px;
  width: 20px;
  line-height: 14px;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 20px;
  color: #fff;
  overflow: hidden;
  text-indent: -500px;
  transition: 0.15s cubic-bezier(0, 0, 0.58, 1);
}

.cycle-pager span:hover, .cycle-pager span:focus {
  transition: none;
}

.cycle-pager span:hover,
.cycle-pager span:focus,
.cycle-pager .cycle-pager-active {
  text-indent: 0;
}

.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;
}

@media (min-width: 768px) {
  .map {
    height: 550px;
  }
}

.social {
  *zoom: 1;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social:before, .social:after {
  display: table;
  content: " ";
  height: auto;
}

.social:after {
  clear: both;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: #FF5619;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3c5b9b;
}

.social__link--facebook:hover, .social__link--facebook:focus {
  background-color: #314a7e;
}

.social__link--twitter {
  background-color: #2daae1;
}

.social__link--twitter:hover, .social__link--twitter:focus {
  background-color: #1d94c9;
}

.social__link--pinterest {
  background-color: #cb2027;
}

.social__link--pinterest:hover, .social__link--pinterest:focus {
  background-color: #a81a20;
}

.social__link--linkedin {
  background-color: #0173b2;
}

.social__link--linkedin:hover, .social__link--linkedin:focus {
  background-color: #015989;
}

.block {
  padding: 0 0 40px 0;
}

.block:after {
  content: "";
  display: block;
  margin: 40px 0 -40px;
}

@media (min-width: 768px) {
  .block {
    padding: 0 0 110px 0;
  }
  .block:after {
    margin: 110px 0 -110px;
  }
}

.block-smaller {
  padding: 0 0 40px 0;
}

.block-smaller:after {
  content: "";
  display: block;
  margin: 40px 0 -40px;
}

@media (min-width: 768px) {
  .block-smaller {
    padding: 0 0 100px 0;
  }
  .block-smaller:after {
    margin: 100px 0 -100px;
  }
}

.yellow {
  color: #F4C500 !important;
}

.yellow a {
  color: #F4C500 !important;
}

.red {
  color: #FF5619 !important;
}

.red a {
  color: #FF5619 !important;
}

.green {
  color: #76C100 !important;
}

.green a {
  color: #76C100 !important;
}

.blue {
  color: #72C8ED !important;
}

.blue a {
  color: #72C8ED !important;
}

.category-menu__item {
  padding: 25px 0;
  user-select: none;
  text-align: center;
  position: relative;
}

.category-menu__item:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  z-index: -1;
  transition: height 0.2s ease-out;
}

.category-menu__item.active {
  pointer-events: none;
}

.category-menu__item .icon {
  font-size: 35px;
  margin-right: 25px;
}

@media (min-width: 1200px) {
  .category-menu__item .icon {
    font-size: 60px;
  }
}

.category-menu__item a {
  text-decoration: none;
  display: inline-block;
  text-align: left;
  min-width: 130px;
}

.category-menu__item a:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .category-menu__item a {
    min-width: 0;
  }
}

@media (min-width: 768px) {
  .category-menu__item {
    padding: 45px 0;
    text-align: center;
  }
}

@media (max-width: 766px) {
  .category-menu__item.yellow {
    background-color: #F4C500;
    color: white !important;
  }
  .category-menu__item.yellow a {
    color: white !important;
  }
}

.category-menu__item.yellow:before {
  background-color: #F4C500;
}

.category-menu__item.yellow:hover, .category-menu__item.yellow.active {
  color: #fff !important;
}

.category-menu__item.yellow:hover a, .category-menu__item.yellow.active a {
  color: #fff !important;
}

.category-menu__item.yellow:hover:before, .category-menu__item.yellow.active:before {
  height: 100%;
}

@media (max-width: 766px) {
  .category-menu__item.red {
    background-color: #FF5619;
    color: white !important;
  }
  .category-menu__item.red a {
    color: white !important;
  }
}

.category-menu__item.red:before {
  background-color: #FF5619;
}

.category-menu__item.red:hover, .category-menu__item.red.active {
  color: #fff !important;
}

.category-menu__item.red:hover a, .category-menu__item.red.active a {
  color: #fff !important;
}

.category-menu__item.red:hover:before, .category-menu__item.red.active:before {
  height: 100%;
}

@media (max-width: 766px) {
  .category-menu__item.green {
    background-color: #76C100;
    color: white !important;
  }
  .category-menu__item.green a {
    color: white !important;
  }
}

.category-menu__item.green:before {
  background-color: #76C100;
}

.category-menu__item.green:hover, .category-menu__item.green.active {
  color: #fff !important;
}

.category-menu__item.green:hover a, .category-menu__item.green.active a {
  color: #fff !important;
}

.category-menu__item.green:hover:before, .category-menu__item.green.active:before {
  height: 100%;
}

@media (max-width: 766px) {
  .category-menu__item.blue {
    background-color: #72C8ED;
    color: white !important;
  }
  .category-menu__item.blue a {
    color: white !important;
  }
}

.category-menu__item.blue:before {
  background-color: #72C8ED;
}

.category-menu__item.blue:hover, .category-menu__item.blue.active {
  color: #fff !important;
}

.category-menu__item.blue:hover a, .category-menu__item.blue.active a {
  color: #fff !important;
}

.category-menu__item.blue:hover:before, .category-menu__item.blue.active:before {
  height: 100%;
}

.circle-link {
  text-decoration: none;
  font-weight: 700;
  display: inline-block;
  position: relative;
  margin: 20px 0;
  padding-left: 19px;
  user-select: none;
}

.circle-link .icon {
  display: inline-block;
  margin: 0 0 4px 4px;
  font-size: 6px;
  color: #000068;
  transition: color 0.05s ease-out;
}

@media (min-width: 768px) {
  .circle-link .icon {
    margin-left: 15px;
  }
}

.circle-link:before {
  content: "";
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #FF5619;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateX(100%) translateY(-50%);
  z-index: -1;
  transition: all 0.2s ease-out;
}

.circle-link:hover {
  color: #000068;
}

.circle-link:hover .icon {
  transition-delay: 0.15s;
  color: #fff;
}

.circle-link:hover:before {
  right: 0;
  transform: translateX(37%) translateY(-50%);
}

.circle-link[disabled] {
  color: #adadad;
  pointer-events: none;
}

.circle-link[disabled]:before {
  background-color: #EFEFEF;
}

.circle-link[disabled] .icon {
  color: #adadad;
}

.card {
  background-color: #F4FCFF;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  transition: all 0.2s ease-out;
  overflow: hidden;
}

.card:before {
  content: "\e90a";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  font-size: 115px;
  font-family: "icomoon" !important;
  speak: none;
  line-height: 1;
  transition: transform 0.4s ease-out;
  transform: translateX(100%) translateY(100%) rotate(-90deg);
}

.card:hover {
  -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.05);
}

.card:hover:before {
  transform: translateX(20%) translateY(30%) rotate(15deg);
}

.card__content {
  padding: 40px 40px 75px 40px;
}

.card__article-preview {
  height: 7em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.card--has-visual {
  background-color: #fff;
  border: 1px solid #efefef;
}

.card--has-visual .visual {
  padding-top: 51%;
}

.card--has-visual:before {
  z-index: 1;
  bottom: auto;
  top: 0;
  transform: translateX(100%) translateY(-100%) rotate(90deg);
}

.card--has-visual:hover:before {
  transform: translateX(30%) translateY(-20%) rotate(-25deg);
}

.card--red .visual {
  border-bottom: 5px solid #FF5619;
}

.card--yellow .visual {
  border-bottom: 5px solid #F4C500;
}

.card--blue .visual {
  border-bottom: 5px solid #72C8ED;
}

.card--green .visual {
  border-bottom: 5px solid #76C100;
}

.card__card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.card__date,
.card__location {
  display: block;
  font-weight: 300;
  margin-right: 2px;
  white-space: nowrap;
}

.card__date .icon,
.card__location .icon {
  color: #FF5619;
  line-height: 24px;
  margin-bottom: 4px;
}

.card__date span,
.card__location span {
  display: inline-block;
  vertical-align: center;
}

.card__date .icon-clock {
  font-size: 15px;
  margin-right: 2px;
}

.card__location {
  margin-bottom: 10px;
}

.card__exclamation {
  font-weight: 300;
}

.card__exclamation .icon {
  color: #FF5619;
  display: inline-block;
  margin-right: 10px;
}

.card__exclamation .icon:before {
  position: relative;
  top: -3px;
}

.card__links {
  position: absolute;
  bottom: 40px;
  z-index: 2;
}

.card__cta {
  margin-right: 20px;
  color: #FF5619;
}

.card__cta:hover, .card__cta:focus {
  color: #e53d00;
}

.card__cta--inactive {
  font-weight: 300;
  color: #FF5619;
  text-decoration: none;
  pointer-events: none;
}

.card__cta--inactive:hover, .card__cta--inactive:focus {
  color: #FF5619;
}

.card__info {
  white-space: nowrap;
  color: #A2A2A2;
}

.card__info:hover, .card__info:focus {
  color: #767676;
}

.review-card {
  position: relative;
  background-color: #F4FCFF;
  overflow: hidden;
  *zoom: 1;
}

.review-card:before, .review-card:after {
  display: table;
  content: " ";
  height: auto;
}

.review-card:after {
  clear: both;
}

@media (min-width: 768px) {
  .review-card {
    padding-left: 33%;
  }
}

.review-card__image {
  display: block;
  width: 100%;
  max-width: 300px;
  height: 230px;
  margin: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

@media (min-width: 768px) {
  .review-card__image {
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 33%;
    max-width: none;
    margin: 0;
  }
}

.review-card__content {
  padding: 20px;
}

@media (min-width: 768px) {
  .review-card__content {
    max-width: 530px;
    padding: 60px 20px;
    margin: 0 auto;
  }
  .review-card__content:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 34px;
    left: 0;
    right: 0;
    background-color: #fff;
  }
}

.review-card__quote {
  font-weight: 700;
  color: #000068;
  min-height: 7em;
}

.review-card__person {
  color: #FF5619;
}

.review-card__person-info {
  color: #000068;
}

.slider-wrap {
  position: relative;
}

.slider {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease-out;
}

.slider .slider__slide {
  display: none;
}

.slider .slider__slide:first-of-type {
  display: block;
}

.slider.slick-initialized {
  opacity: 1;
  visibility: visible;
}

.slider.slick-initialized .slider__slide {
  display: block;
}

.slider__slide {
  display: block !important;
}

.slider__actions {
  display: block;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  margin: 10px 0;
  user-select: none;
}

@media (min-width: 768px) {
  .slider__actions {
    position: absolute;
    margin: 10px 0 0 0;
  }
}

.slider__dots {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  *zoom: 1;
  max-width: 55%;
}

.slider__dots:before, .slider__dots:after {
  display: table;
  content: " ";
  height: auto;
}

.slider__dots:after {
  clear: both;
}

@media (min-width: 768px) {
  .slider__dots {
    max-width: none;
  }
}

.slider__dots ul {
  list-style: none;
  margin: 0;
}

.slider__dots li {
  float: left;
  padding: 14px 10px;
  position: relative;
}

.slider__dots li:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #FF5619;
  border-radius: 50%;
  transition: opacity 0.2s ease-out;
  opacity: 0.5;
}

.slider__dots li.slick-active {
  pointer-events: none;
}

.slider__dots li.slick-active:before {
  opacity: 1;
}

.slider__dots li:hover {
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.slider__dots button {
  visibility: hidden;
  height: 6px;
  width: 6px;
  padding: 0;
  margin: 0;
}

.slider__action-left,
.slider__action-right {
  display: inline-block;
  vertical-align: middle;
  font-size: 6px;
  padding: 5px 9px;
  color: #FF5619;
}

.slider__action-left:hover,
.slider__action-right:hover {
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.floating-card {
  position: relative;
  background-color: #fff;
  overflow: hidden;
}

@media (min-width: 768px) {
  .floating-card {
    padding: 50px;
    -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
  }
}

@media (min-width: 1200px) {
  .floating-card {
    padding: 50px 100px;
  }
}

.floating-card-wrap--review-score {
  padding-top: 50px !important;
  color: #000068;
  font-size: 18px;
  text-align: center;
}

.floating-card-wrap--review-score .accent {
  color: #FF5619;
}

@media (min-width: 768px) {
  .floating-card-wrap--review-score {
    font-size: 20px;
  }
}

.floating-card-wrap--review-score:before {
  height: 100px !important;
}

.floating-card-wrap--review-score .floating-card {
  margin-bottom: 42px;
  position: relative;
  z-index: 0;
  overflow: visible;
}

.floating-card-wrap--review-score .floating-card:before {
  content: "";
  display: block;
  width: 150px;
  height: 140px;
  background-image: url("../images/thumb-up.png");
  background-size: contain;
  background-position: bottom left;
  position: absolute;
  bottom: -20px;
  left: 0;
  z-index: -1;
}

@media (min-width: 768px) {
  .floating-card-wrap--review-score .floating-card:before {
    bottom: 0;
  }
}

.floating-card-wrap--review-score .floating-card p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .home-newsletter .floating-card {
    z-index: 0;
  }
  .home-newsletter .floating-card:before {
    font-family: "icomoon" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e90a";
    display: block;
    font-size: 170px;
    color: #F4FCFF;
    position: absolute;
    left: -55px;
    bottom: 45px;
    transform: scaleX(-1) rotate(-15deg);
    z-index: -1;
  }
}

@media (min-width: 768px) {
  .newsletter__content {
    margin-top: 10px;
  }
}

.newsletter-form {
  position: relative;
  transform: translateX(-13px);
}

.newsletter-form .checkbox-statement {
  margin-left: 13px;
  margin-bottom: 26px;
}

.newsletter-form .checkbox-statement label {
  font-size: 13px;
  padding-top: 3px;
  color: #A2A2A2;
}

.newsletter-form .form__item-profession {
  display: flex;
  align-items: center;
  padding: 0 13px;
}

.newsletter-form__email input {
  border: 0 !important;
  box-shadow: none !important;
  padding: 25px 14px 11px 14px !important;
}

.newsletter-form__email:before, .newsletter-form__email:after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  left: 13px;
  right: 0;
  bottom: -10px;
  background-color: #A2A2A2;
  z-index: 1;
}

.floating-card-wrap {
  position: relative;
}

.floating-card-wrap .floating-card__bg {
  display: block;
  width: 100%;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .floating-card-wrap {
    padding: 75px 40px 0 40px;
  }
  .floating-card-wrap:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 225px;
    width: 100%;
    background-color: #F4FCFF;
  }
  .floating-card-wrap .floating-card__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 225px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .floating-card-wrap {
    padding: 75px 100px 0 100px;
  }
}

@media (min-width: 768px) {
  .floating-card-wrap--article {
    padding-top: 205px;
  }
  .floating-card-wrap--article .floating-card__bg {
    height: 275px;
  }
}

@media (min-width: 768px) {
  .floating-card-wrap--article .floating-card {
    padding-top: 70px;
    box-shadow: none !important;
  }
}

.newsletter-form__submit {
  position: absolute;
  bottom: 0;
  right: 0;
}

.newsletter-form__submit input {
  margin: 0;
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.newsletter-form__submit:before {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #000068;
  font-size: 6px;
  transition: color 0.2s ease-out;
}

.newsletter-form__submit:hover input {
  background-color: #FF5619;
}

.newsletter-form__submit:hover:before {
  color: #fff;
}

.newsletter-form__submit[disabled]:before {
  color: #fff;
}

@media (max-width: 766px) {
  .newsletter-form__submit input {
    background-color: #FF5619;
  }
  .newsletter-form__submit:before {
    color: #fff;
  }
}

.tab-panel {
  display: none;
}

.tab-panel.active {
  display: block;
}

.tab-control {
  user-select: none;
}

.breadcrumb {
  color: #FF5619;
  font-weight: 600;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .breadcrumb {
    margin-bottom: 55px;
  }
}

.breadcrumb a {
  color: #FF5619;
  text-decoration: none;
}

.breadcrumb a:hover {
  color: #e53d00;
}

.breadcrumb .icon-arrow-left {
  display: inline-block;
  vertical-align: middle;
  font-size: 6px;
  margin-bottom: 4px;
  margin-right: 5px;
}

.intro {
  position: relative;
}

@media (min-width: 768px) {
  .intro:before {
    font-family: "icomoon" !important;
    font-size: 430px;
    line-height: 430px;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(-13deg);
    transform-origin: bottom left;
    opacity: 0.05;
    z-index: -1;
  }
  .intro.intro--yellow:before {
    content: "\e907";
    color: #F4C500;
  }
  .intro.intro--red:before {
    content: "\e906";
    color: #FF5619;
  }
  .intro.intro--blue:before {
    content: "\e904";
    color: #72C8ED;
  }
  .intro.intro--green:before {
    content: "\e905";
    color: #76C100;
  }
}

.control-list-title {
  color: #000068;
  background-color: rgba(0, 0, 104, 0.06);
  font-weight: 300;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .control-list-title {
    font-size: 18px;
  }
}

.control-list {
  position: relative;
  *zoom: 1;
  padding: 10px;
}

.control-list:before, .control-list:after {
  display: table;
  content: " ";
  height: auto;
}

.control-list:after {
  clear: both;
}

.control-list dt {
  color: #000068;
  font-weight: 400;
}

.control-list dd {
  color: #4B4B4B;
}

@media (min-width: 768px) {
  .control-list dt {
    width: 50%;
    padding-right: 20px;
    float: left;
    clear: left;
    margin-bottom: 15px;
  }
  .control-list dd {
    width: 50%;
    float: left;
  }
}

.control-list--participant {
  background-color: #fff;
  border: 1px solid #EFEFEF;
  padding: 10px;
}

.control-list--participant dt:last-of-type {
  margin-bottom: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999999999;
  display: none;
}

.modal.show {
  display: block;
}

.modal__content {
  margin: 0 auto;
  background-color: #fff;
  padding: 5px;
  position: absolute;
  width: 100%;
  max-height: 95vh;
  max-height: calc(100vh - 85px);
  max-width: 1200px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: auto;
  min-width: 300px;
}

@media (min-width: 768px) {
  .modal__content {
    max-height: 95vh;
    padding: 20px;
  }
}

.modal__body {
  overflow-y: auto;
  max-height: 95vh;
  max-height: calc(100vh - 90px);
  padding: 20px 0;
  background-color: #fff;
}

@media (min-width: 768px) {
  .modal__body {
    max-height: 95vh;
    max-height: calc(95vh - 120px);
  }
}

.modal__close {
  position: absolute;
  right: 10px;
  top: -44px;
  transition: transform 0.2s ease-out;
  transform: rotate(0deg);
  font-size: 14px;
  padding: 10px;
  color: #fff;
}

@media (min-width: 768px) {
  .modal__close {
    right: -10px;
  }
}

.modal__close:hover {
  cursor: pointer;
  transform: rotate(90deg);
}

.img-full-width {
  width: 100%;
}

.accordion-trigger {
  cursor: pointer;
}

select {
  visibility: hidden;
  opacity: 0;
}

.add-button {
  float: none;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 40px;
}

.add-button:before {
  display: inline-block;
  content: "+";
  font-weight: 300;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: transform 0.2s ease-out;
}

.close-button {
  float: none;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 40px;
  transition: all 0.2s ease-out;
}

.close-button:before {
  display: inline-block;
  content: "+";
  font-weight: 300;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transition: transform 0.2s ease-out;
}

.tooltip {
  display: inline-block;
  font-size: 12px;
  background-color: rgba(0, 0, 104, 0.06);
  color: #000068;
  padding: 10px 10px;
  border-radius: 3px;
  position: relative;
  user-select: none;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.15s ease-out;
}

.tooltip:before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border: 5px solid transparent;
  border-top: 3px solid rgba(0, 0, 104, 0.06);
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip--hidden {
  opacity: 0;
}

ul.pagination {
  list-style: none;
  *zoom: 1;
}

ul.pagination:before, ul.pagination:after {
  display: table;
  content: " ";
  height: auto;
}

ul.pagination:after {
  clear: both;
}

ul.pagination li {
  float: left;
  margin: 0 10px;
  position: relative;
}

ul.pagination li:first-of-type {
  margin-left: 0;
}

ul.pagination li:last-of-type {
  margin-right: 0;
}

ul.pagination li.active {
  color: #000068;
}

ul.pagination li.disabled {
  color: #A2A2A2;
}

ul.pagination li a {
  color: #A2A2A2;
}

ul.pagination li a:hover {
  color: #000068;
}

ul.pagination li:before {
  content: "";
  display: block;
  width: 6px;
  height: 1px;
  background-color: #A2A2A2;
  position: absolute;
  right: -13px;
  top: 50%;
  transform: translateY(-50%);
}

ul.pagination li:first-of-type:before, ul.pagination li:last-of-type:before, ul.pagination li:nth-last-child(2):before {
  content: none;
}

/* =============================================================================
   PAGES
   ========================================================================== */
.visual-intro {
  position: relative;
  font-weight: 300;
  color: #000068;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .visual-intro {
    margin-bottom: 0;
  }
}

.visual-intro h1 {
  font-weight: 300;
}

.visual-intro:before {
  content: "";
  display: block;
  width: 110%;
  height: 0;
  padding-top: 66.66%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .visual-intro:before {
    height: auto;
    width: auto;
    padding-top: 0;
    position: absolute;
    top: 0;
    left: 20px;
    bottom: 0;
    right: 38%;
    background-position: 75% 20%;
  }
}

.visual-intro--home:before {
  background-image: url("../images/home-hero--mobile.jpg");
}

@media (min-width: 768px) {
  .visual-intro--home:before {
    background-image: url("../images/home-hero.jpg");
  }
}

@media (min-width: 768px) {
  .visual-intro--home {
    padding-bottom: 85px;
  }
}

.visual-intro--about:before {
  background-image: url("../images/wie-we-zijn--mobile.jpg");
}

@media (min-width: 768px) {
  .visual-intro--about:before {
    background-image: url("../images/wie-we-zijn.jpg");
  }
}

.visual-intro__content {
  padding: 40px 0;
  position: relative;
  z-index: 1;
}

.visual-intro__content:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -20px;
  right: -20px;
  bottom: 0;
  background-color: #fff;
  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
  z-index: -1;
}

@media (min-width: 768px) {
  .visual-intro__content:before {
    left: 0;
    right: -100px;
  }
}

@media (min-width: 768px) {
  .visual-intro__content {
    padding: 115px 30px 115px 65px;
  }
}

@media (min-width: 1680px) {
  .visual-intro__content {
    padding: 150px 0 150px 110px;
  }
}

@media (min-width: 768px) {
  .visual-intro__content--smaller {
    padding: 75px 30px 75px 65px;
  }
}

@media (min-width: 1680px) {
  .visual-intro__content--smaller {
    padding: 75px 0 75px 110px;
  }
}

.circle-link--calendar {
  margin-top: 20px;
}

.home-about__visual {
  margin-bottom: 40px;
  padding-top: 70%;
}

.home-about__visual .visual__img {
  background-image: url("../images/wie-we-zijn--mobile.jpg");
}

@media (min-width: 768px) {
  .home-about__visual .visual__img {
    background-image: url("../images/wie-we-zijn.jpg");
  }
}

@media (min-width: 768px) {
  .home-about__visual {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .home-about__content {
    max-width: 385px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.home-about__circle-link {
  text-align: right;
}

@media (min-width: 768px) {
  .home-about__circle-link {
    text-align: left;
  }
}

.home-kmo__content {
  background-color: #F4FCFF;
  position: relative;
  z-index: 1;
}

@media (min-width: 768px) {
  .home-kmo__content:before {
    content: "";
    display: block;
    width: 130px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #fff;
    z-index: -1;
  }
}

.home-kmo__left {
  padding: 40px;
}

.home-kmo__left p:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .home-kmo__left {
    padding: 140px 100px;
  }
}

.home-kmo__right {
  background-color: #fff;
  max-width: 400px;
  margin: 0 auto;
}

.home-kmo__right p {
  color: #A2A2A2;
}

.home-kmo__right p a {
  color: #A2A2A2;
}

.home-kmo__right p a:hover {
  color: #767676;
}

@media (min-width: 768px) {
  .home-kmo__right {
    margin-top: 85px;
    margin-bottom: 70px;
  }
}

.home-kmo__right-button {
  border: 1px solid #F4F4F4;
  padding: 20px 25px;
}

@media (min-width: 768px) {
  .home-kmo__right-button {
    padding: 23px 50px;
  }
}

.home-kmo__right-button p:last-of-type {
  margin-bottom: 0;
}

.home-kmo__right-title {
  color: #000068;
  font-weight: 600;
  margin-bottom: 15px;
}

.home-kmo__text {
  max-width: 515px;
}

.aanbod-intro {
  position: relative;
}

.aanbod-intro__content {
  max-width: 720px;
}

.beroep-intro,
.event-intro {
  position: relative;
}

.beroep-intro__content,
.event-intro__content {
  max-width: 760px;
}

@media (min-width: 768px) {
  .event-intro h1 {
    width: calc(100% - 160px);
  }
}

.event-intro p:first-of-type {
  clear: right;
}

.event-intro__register-link {
  float: right;
  padding: 20px 40px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
}

@media (max-width: 766px) {
  .event-intro__register-link {
    padding: 15px 20px;
    font-size: 12px;
    margin-top: 0;
  }
}

.event-intro__register-link.hidden--tablet {
  margin-bottom: 25px;
}

.event-intro__register-link .icon {
  font-size: 6px;
  transform: translateY(2px) rotate(90deg);
  margin-right: 0;
}

.event-intro__register-link:focus {
  color: #fff;
  background-color: #FF5619;
}

.event-intro__register-link:hover {
  color: #fff;
  background-color: #e53d00;
}

.list-filters {
  margin: 50px 0;
}

.list-filters .tab-list {
  text-align: right;
  margin-bottom: 30px;
}

.list-filters .tab-list.hidden--tablet {
  margin-top: 30px;
  text-align: center;
  font-size: 16px;
}

@media (min-width: 768px) {
  .list-filters .tab-list {
    float: right;
  }
}

.list-filters .tab-list__tab {
  display: inline-block;
}

.list-filters .tab-list__tab a {
  color: #A2A2A2;
}

.list-filters .tab-list__tab.active {
  pointer-events: none;
}

.list-filters .tab-list__tab.active a {
  text-decoration: none;
  color: #000068;
}

.list-filters .tab-list__tab.active a:hover {
  color: initial;
}

.list-filters .select-wrap {
  display: inline-block;
  position: relative;
}

.list-filters--home {
  margin-top: 0;
}

.list-filters__sentence {
  font-size: 18px;
  font-weight: 300;
  color: #000068;
  margin-bottom: 45px;
}

.list-filters__sentence select {
  display: inline-block;
  width: auto;
}

@media (min-width: 768px) {
  .list-filters__sentence {
    font-size: 24px;
  }
}

.list-filters__submit {
  border-radius: 50%;
  float: none;
  margin: 0;
  margin-left: 15px;
  padding: 10px;
  font-size: 11px;
}

@media (min-width: 768px) {
  .list-filters__submit {
    padding: 15px;
    font-size: 16px;
  }
}

.list-filters__tags-clarification {
  display: block;
  margin-right: 45px;
  color: #A2A2A2;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .list-filters__tags-clarification {
    display: inline-block;
    width: auto;
    margin-bottom: 0;
  }
}

.list-filters__tags {
  display: inline;
  vertical-align: middle;
}

.list-filters__tags input {
  display: none;
}

.list-filters__tags label {
  background-color: #fff;
  color: #000068;
  display: inline-block;
  padding: 1px 14px;
  border: 1px solid #EFEFEF;
  user-select: none;
  margin: 5px 10px 5px 0;
  width: 45%;
  text-align: center;
}

.list-filters__tags label:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .list-filters__tags label {
    width: auto;
  }
}

.list-filters__tags input:checked + label {
  background-color: #72C8ED;
  color: #fff;
}

.workshop-details dt {
  color: #000068;
}

.workshop-details dd {
  margin-bottom: 15px;
}

.customer-use-kmo-wallet__info {
  font-size: 13px;
  display: inline-block;
  margin-top: 5px;
}

.register-workshop__next {
  float: right;
}

.register-workshop__previous {
  display: inline-block;
  margin: 20px 0;
}

.register-workshop__tab-navigation--centered {
  position: relative;
}

@media (min-width: 768px) {
  .register-workshop__tab-navigation--centered .register-workshop__previous {
    position: absolute;
    right: 50%;
    right: calc(50% + 110px);
  }
  .register-workshop__tab-navigation--centered .register-workshop__next {
    float: none;
    position: absolute;
    left: 50%;
    left: calc(50% + 10px);
  }
}

.register-date {
  margin: 20px 0;
}

.register-step-label {
  margin-bottom: 20px;
  font-weight: 700;
  color: #000068;
}

@media (min-width: 768px) {
  .register-step-label {
    font-size: 22px;
    line-height: 1.2em;
  }
}

.register__step-tab-list {
  position: relative;
  text-align: center;
  user-select: none;
  margin-bottom: 40px;
  margin-top: 35px;
}

@media (min-width: 768px) {
  .register__step-tab-list {
    margin-bottom: 80px;
  }
}

.register__step-tab-list ul {
  margin: 0;
}

.register__step-tab-list:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  height: 2px;
  width: 100%;
  background-color: #000068;
}

.register-step__tab {
  display: inline-block;
  position: relative;
  margin: 0 23px;
  border-radius: 50%;
  background-color: #000068;
}

.register-step__tab.active, .register-step__tab:hover {
  background-color: #FF5619;
}

.register-step__tab a {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  display: block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  position: relative;
  text-align: center;
}

@media (min-width: 768px) {
  .register-step__tab a {
    font-size: 19px;
    width: 38px;
    height: 38px;
    line-height: 38px;
  }
}

.register-step__tab a:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.register__participant-card {
  position: relative;
}

.register__participant-card-content {
  margin-bottom: 40px;
  background-color: #fff;
  opacity: 1;
  height: 301px;
  position: relative;
  transition: all 0.6s ease-out;
  transform: translateY(0);
  padding: 20px;
  border: 1px solid #EFEFEF;
  overflow: hidden;
}

@media (min-width: 768px) {
  .register__participant-card-content {
    padding: 40px;
  }
}

.register__participant-card-content fieldset .form__item:last-of-type {
  margin-bottom: 0;
}

.register__card-close-button {
  position: absolute;
  top: -28px;
  right: -16px;
  z-index: 1;
  transition: all 0.6s ease-out;
  float: none;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 40px;
}

.register__card-close-button:before {
  display: inline-block;
  content: "+";
  font-weight: 300;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transition: transform 0.2s ease-out;
}

.register__participant-card--hidden .close-button,
.register__participant-card--hidden .register__card-close-button {
  opacity: 0;
  top: -8px;
}

.register__participant-card--hidden .register__participant-card-content {
  transform: translateY(30px);
  opacity: 0;
  height: 0;
  margin-bottom: 0;
  padding: 0;
}

.register__participant-card-template {
  display: none;
}

.register__add-button-wrap {
  text-align: center;
  position: relative;
  padding-top: 38px;
  padding-bottom: 38px;
}

.register__add-button-wrap .add-button {
  margin: 0;
}

.register__add-button-wrap:before {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 104, 0.08);
}

.register-status h1 {
  padding-top: 50px;
}

.input-group--radio {
  border: 1px solid #EFEFEF;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 20px;
}

.radio {
  padding: 15px;
  border-bottom: 1px solid #EFEFEF;
  display: flex;
  align-items: center;
}

.radio label {
  cursor: pointer;
  display: block;
  padding-left: 20px;
  width: 100%;
  margin-bottom: 0px;
}

.radio label > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.radio-input {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-color: #EFEFEF;
}

.tooltip-add-participant {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.about-who__visual {
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.about-who__visual .visual {
  padding-top: 71.5%;
}

@media (min-width: 768px) {
  .about-who__visual {
    margin-bottom: 0;
  }
}

.about-who__content p:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .about-who__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .about-team__grid:nth-of-type(2n + 3) {
    clear: left;
  }
}

@media (min-width: 1024px) {
  .about-team__grid:nth-of-type(3n + 4) {
    clear: left;
  }
}

.team-member {
  margin-bottom: 20px;
  position: relative;
}

.team-member .visual {
  margin-bottom: 25px;
}

.team-member .visual:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000068;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.team-member .visual:after {
  content: "+";
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-weight: 700;
  font-size: 51px;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(0.7);
  color: #fff;
  z-index: 1;
  opacity: 0;
  transition: all 0.4s ease-out;
}

.team-member:hover .visual:before {
  opacity: 0.5;
}

.team-member:hover .visual:after {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) rotate(0deg) scale(1);
}

.team-member__name a {
  font-weight: 700;
  color: #000068;
  text-decoration: none;
}

.team-member__name a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.team-member__name a:hover {
  text-decoration: none;
}

.modal-team-member .modal__content {
  width: 100%;
}

@media (min-width: 768px) {
  .modal-team-member__left-content {
    max-width: 380px;
  }
}

.modal-team-member__picture {
  margin-bottom: 15px;
}

.modal-team-member__contact-info {
  color: #FF5619;
  font-weight: 700;
  margin-bottom: 40px;
}

.modal-team-member__contact-info a {
  color: #FF5619;
  text-decoration: none;
}

.modal-team-member__contact-info a:hover {
  color: #e53d00;
  text-decoration: underline;
}

.modal-team-member__desc {
  margin-bottom: 40px;
}

.modal-team-member__personal-motivation {
  background-color: #F4FCFF;
  padding: 20px;
  margin-bottom: 40px;
  color: #000068;
  font-weight: 700;
}

@media (min-width: 768px) {
  .modal-team-member__personal-motivation {
    padding: 50px;
  }
}

.modal-team-member__education ul {
  margin: 0;
  list-style: none;
  font-size: 13px;
}

.modal-team-member__education li {
  border-top: 1px solid #A2A2A2;
  padding: 10px 0;
}

.modal-team-member__education li:first-of-type {
  border-top: 0;
}

#contact-form .checkbox-statement {
  float: left;
  max-width: 350px;
}

#contact-form .contact__submit {
  float: right;
}

.news__content {
  margin-bottom: 40px;
}

.news__content h1 {
  margin-bottom: 30px;
}

.news__navigation {
  text-align: center;
}

.news__navigation a {
  color: #FF5619;
  display: inline-block;
  margin: 15px 20px;
}

.news__navigation a:hover {
  color: #e53d00;
}

.news__navigation a.back-to-overview {
  color: #A2A2A2;
}

.news__navigation a.back-to-overview:hover {
  color: #767676;
}

@media (min-width: 768px) {
  .news__navigation {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .news__navigation a {
    margin-left: 0;
    margin-right: 0;
  }
}

.about-story__step {
  position: relative;
  padding-left: 60px;
}

.about-story__step:not(:first-of-type) {
  padding-top: 20px;
}

.about-story__step:not(:first-of-type) .about-story__step-nr {
  top: 25px;
}

.about-story__step:not(:first-of-type) .accordion-trigger:after {
  top: 20px;
}

.about-story__step:after {
  content: "";
  display: block;
  margin: 40px 0 -40px;
}

@media (min-width: 768px) {
  .about-story__step {
    padding-left: 200px;
  }
}

.about-story__step .accordion-trigger:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  top: 0;
  bottom: 0;
  left: 40px;
  background-color: #FF5619;
  transition: all 0.2s ease-out;
  opacity: 1;
}

@media (min-width: 768px) {
  .about-story__step .accordion-trigger:before {
    left: 175px;
    width: 5px;
  }
}

.about-story__step .accordion-trigger:after {
  content: "-";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  color: #FF5619;
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .about-story__step .accordion-trigger:after {
    font-size: 24px;
    position: static;
    display: inline;
  }
}

.about-story__step .accordion-trigger.active:before {
  opacity: 0.5;
}

.about-story__step .accordion-trigger.active:after {
  content: "+";
}

.about-story__step .accordion-trigger h3 {
  display: inline-block;
  padding-right: 20px;
}

.about-story__step-content h3 {
  font-size: 18px;
}

@media (min-width: 768px) {
  .about-story__step-content h3 {
    font-size: 24px;
  }
}

.about-story__step-text {
  width: 100% !important;
  overflow: hidden;
}

.about-story__step-nr {
  font-size: 20px;
  font-weight: 700;
  color: #FF5619;
  position: absolute;
  left: 0;
  top: 5px;
}

@media (min-width: 768px) {
  .about-story__step-nr {
    left: 100px;
    font-size: 24px;
  }
}

#map {
  height: 500px;
}

.discount-code-wrap {
  margin-bottom: 15px;
}

.discount-code-wrap .form__item {
  display: flex;
  align-items: center;
}

.discount-code-wrap .form__item div {
  flex-grow: 1;
}

.discount-code-wrap .form__item button {
  margin: 0;
  padding: 9px 20px;
}

.js-check-discount-code__response__message {
  display: none;
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }
  html {
    font-size: 10px;
  }
  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  .page-footer,
  .main-nav {
    display: none;
  }
  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}
